import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Table from "./Table";

import baseUrl, {
  authentication,
  deductPoints,
  updateFirstName,
  updateLastName,
  newPhoneNumb,
  updatePoints,
  updateStudentClass,
} from "../Utils";

class ClassDetails extends React.Component {
  constructor(props) {
    super(props);
    // this.getStudents()
    this.state = {
      studentData: [],
      newSubcategoryOpen: false,
    };
    this.addColumns();
    this.getStudents();
  }
  studentColumns = [
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
      row: 1,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
      row: 1,
    },
    {
      dataField: "points",
      text: "Points",
      sort: true,
      row: 1,
    },
    {
      dataField: "absent",
      text: "Days Absent",
      sort: true,
      editable: false,
    },
    {
      dataField: "class",
      text: "Class",
      sort: true,
      editable: false,
    },
    {
      dataField: "class_id",
      text: "Class ID",
      sort: true,
    },
  ];
  addColumns(){
    if(newPhoneNumb){
        this.studentColumns.splice(2,0,    {
          dataField: "address",
          text: "Address",
          sort: true,
          row: 1,
        },
        {
          dataField: "phone",
          text: "Phone Number",
          sort: true,
          row: 1,
        });
    }
  }
  getStudents() {
    fetch(`${baseUrl}/students`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.map((item) => {
          item.id = parseInt(item.id);
          item.points = parseInt(item.points);
        });

        this.setState({ studentData: res });
      });
  }

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Students</Breadcrumb.Item>
        </Breadcrumb>

        <div style={{ padding: "15px" }}>
          {this.state.studentData != undefined ? (
            <Table
              delete={false}
              editFunc={(oldValue, newValue, row, column) => {
                {
                  if (oldValue != newValue) {
                    if (column.dataField == "points") {
                      let points = newValue - oldValue;
                      deductPoints(row.id, points);
                    }
                    if (column.dataField == "first_name") {
                      updateFirstName(row.id, newValue);
                    }
                    if (column.dataField == "last_name") {
                      updateLastName(row.id, newValue);
                    }
                    if (column.dataField == "class_id") {
                      updateStudentClass(row.id, newValue);
                    }
                  }
                }
              }}
              columns={this.studentColumns}
              data={this.state.studentData}
              rowEvents={{
                onClick: (e, row, rowIndex) => {
                  console.log(e);
                  if (e.type == "click") {
                    //this.props.stateFunc(row.image)
                    this.props.history.push({
                      pathname: `/student-details/${row.id}`,
                      state: {
                        class: row.class,
                        studentId: row.id,
                        firstName: row.first_name,
                        lastName: row.last_name,
                        classId: row.id,
                        grade: row.grade,
                        gradeId: row.grade_id,
                      },
                    });
                  }
                },
              }}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
export default ClassDetails;
