import React from "react";

/**
 * Created by Yosef Nussen
 * Date: 6/28/20
 */
class TopWarning extends React.Component {
  constructor(props) {
    super(props);

    switch (this.props.type) {
      case "Assignment":
        this.background = "#ed4337";
        break;
      case "No privlages":
        this.background = "#6c757d";
        break;
      case "Warning":
        this.background = "#ffc107";
    }
  }
  color = "white";
  background = "#ed4337";
  render() {
    return (
      <div
        style={{
          background: this.background,
          height: "50px",
          textAlign: "center",
          color: this.color,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h3>{this.props.type}!</h3>
      </div>
    );
  }
}
export default TopWarning;
