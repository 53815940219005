import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { authentication } from "../Utils";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import baseUrl from "../Utils";
import CSVReader from "react-csv-reader";

class UpdateStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
    };
  }

  open = () => {
    this.setState({
      isDialogOpen: true,
    });
  };
  students = "";

  componentWillReceiveProps(nextProps) {
    switch (nextProps.isDialogOpen) {
      case true: {
        this.setState({
          isDialogOpen: true,
        });
        break;
      }
      case false: {
        this.setState({
          isDialogOpen: false,
        });
        break;
      }
    }
  }

  bulkUpdateStudents = () => {
    if (this.students == "") {
      if (NotificationManager.listNotify.length == 0)
        NotificationManager.error(
          "Please choose a valid csv file with a valid template.",
          "",
          5000,
          () => {}
        );
    } else {
      this.students = { students: this.students };
      let formData = new FormData();
      formData.append("students", JSON.stringify(this.students));
      fetch(
        `${baseUrl}/students/bulk-update`,
        {       
          method: "POST",
          body: formData,
          headers: {
            Authorization: authentication,
          },
        }
      )
        .then((response) => response.text())

        .then((text) => {
          this.students = "";

          if (text == "successful") {
            this.props.closeFunction();
          }else{
            alert(text);
          }
        });
    }
  };

  render() {
    return (
      <Modal
        show={this.state.isDialogOpen}
        onHide={this.handleSubcategoryClose}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>Update Existing Students from csv file</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NotificationContainer />
          <div style={{ textAlign: "center" }}>
            <CSVReader
              onFileLoaded={(data, fileInfo) => {
                this.students = data;
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              this.props.closeFunction();
              this.setState({ channelName: "" });
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              this.bulkUpdateStudents();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

UpdateStudent.propTypes = {};

export default UpdateStudent;
