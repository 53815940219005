import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Table from "./Table";
import Button from "react-bootstrap/Button";

import baseUrl, {
  authentication,
  deductPoints,
  updateFirstName,
  updateLastName,
  updatePoints,
} from "../Utils";
import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import { Type } from "react-bootstrap-table2-editor";
//import Textarea from "bootstrap-4-react/src/components/dom/Textarea";
const options = [
  // { value: '1', label: '1' },
  { value: "-1", label: "-1" },
  { value: "-2", label: "-2" },
  { value: "-3", label: "-3" },
  { value: "-4", label: "-4" },
  { value: "-5", label: "-5" },
];

class Redeem extends React.Component {
  reset = () => {
    this.setState(
      {
        studentData: [],
        date: "",
        formattedDate: "",
        submitEnabled: true,
      },
      this.setClass()
    );
  };
  constructor(props) {
    super(props);
    // this.getStudents()
    this.state = {
      studentData: [],
      newSubcategoryOpen: false,
      canteen: [],
      specialty: [],
      date: "",
      formattedDate: "",
      submitEnabled: true,
    };
    this.setClass();
  }

  studentColumns = [
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "points",
      // searchable:false,
      text: "Points",
      sort: true,
      editable: true,
    },
    {
      dataField: "canteen0",
      //searchable:false,
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return this.state.canteen;
        },
      },
      text: "canteen",
      sort: true,
      editable: true,
    },
    {
      dataField: "canteen1",
      //searchable:false,
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return this.state.canteen;
        },
      },
      text: "canteen",
      sort: true,
      editable: true,
    },
    {
      dataField: "canteen2",
      //searchable:false,
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return this.state.canteen;
        },
      },
      text: "canteen",
      sort: true,
      editable: true,
    },

    {
      dataField: "specialty0",
      // searchable:false,
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return this.state.specialty;
        },
      },
      text: "Specialty Items",
      sort: true,
      editable: true,
    },

    {
      dataField: "canteen3",
      //searchable:false,
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return this.state.canteen;
        },
      },
      text: "canteen",
      sort: true,
      editable: true,
    },

    {
      dataField: "canteen4",
      //searchable:false,
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return this.state.canteen;
        },
      },
      text: "canteen",
      sort: true,
      editable: true,
    },

    {
      dataField: "specialty1",
      //searchable:false,
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return this.state.specialty;
        },
      },
      text: "Specialty Items",
      sort: true,
      editable: true,
    },

    {
      dataField: "class",
      //searchable:false,
      text: "Class",
      sort: true,
    },
  ];

  setClass() {
    fetch(`${baseUrl}/students`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.map((item) => {
          item.id = parseInt(item.id);
          item.points = parseInt(item.points);
          item.canteen = [];
          item.canteen0 = "";
          item.canteen1 = "";
          item.canteen2 = "";
          item.canteen3 = "";
          item.canteen4 = "";
          item.specialty0 = "";
          item.specialty1 = "";
          item.specialty = [];
        });
        this.getCanteen(res);

        // this.setState({studentData:res})
      });
  }
  getCanteen(stud) {
    fetch(`${baseUrl}/canteen-items`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.map((item, index) => {
          item.id = parseInt(item.id);
          item.points = parseInt(item.points);
          item.label = item.name;
          item.value = item.name;
        });
        res.push({ label: "Select...", value: "Select..." });
        this.getSpecialty(res, stud);

        //this.setState({canteen:res})
      });
  }
  getSpecialty(can, stud) {
    fetch(`${baseUrl}/specialty-items`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.map((item, index) => {
          item.id = parseInt(item.id);
          item.points = parseInt(item.points);
          item.label = item.name;
          item.value = item.name;
        });
        res.push({ label: "Select...", value: "Select..." });

        this.setState({ specialty: res, canteen: can, studentData: stud });
      });
  }
  validate = () => {
    if (this.state.formattedDate == "") {
      alert("Please Select A Date.");
      return false;
    }
    return true;
  };
  getSpecialtyId(item) {
    if (item == "Select...") {
      return "";
    }
    let it = "";
    this.state.specialty.map((i) => {
      if (i.name == item) {
        it = i;
      }
    });
    return it;
  }
  getCanteenId(item) {
    if (item == "Select...") {
      return "";
    }
    let it = "";
    this.state.canteen.map((i, x) => {
      if (i.value == item) {
        it = i;
        return i.id;
      }
    });

    return it;
  }
  submit = () => {
    if (this.validate() && this.state.submitEnabled == true) {
      this.setState({ submitEnabled: false });
      let d = this.state.studentData;
      d.map((s) => {
        if (s.canteen0 != "") {
          console.log(s.canteen0);
          let i = this.getCanteenId(s.canteen0);
          if (i != "") {
            console.log(i);
            s.canteen.push(i);
          }
        }
        if (s.canteen1 != "") {
          let i = this.getCanteenId(s.canteen1);
          if (i != "") {
            s.canteen.push(i);
          }
        }
        if (s.canteen2 != "") {
          let i = this.getCanteenId(s.canteen2);
          if (i != "") {
            s.canteen.push(i);
          }
        }
        if (s.canteen3 != "") {
          let i = this.getCanteenId(s.canteen3);
          if (i != "") {
            s.canteen.push(i);
          }
        }
        if (s.canteen4 != "") {
          let i = this.getCanteenId(s.canteen4);
          if (i != "") {
            s.canteen.push(i);
          }
        }
        if (s.specialty0 != "") {
          let i = this.getSpecialtyId(s.specialty0);
          if (i != "") {
            s.specialty.push(i);
          }
        }
        if (s.specialty1 != "") {
          let i = this.getSpecialtyId(s.specialty1);
          if (i != "") {
            s.specialty.push(i);
          }
        }
      });
      console.log(d);
      let students = { students: d, date: this.state.formattedDate };
      students = JSON.stringify(students);

      let formData = new FormData();
      formData.append("data", students);
      fetch(`${baseUrl}/redeem-rewards`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())

        .then((text) => {
          students = "";

          if (text == "successful") {
            this.reset();
          } else {
            alert("error" + text);
          }
        });
    }
  };

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Rewards</Breadcrumb.Item>
          <Breadcrumb.Item active>Redeem</Breadcrumb.Item>
        </Breadcrumb>

        <div style={{ padding: "15px" }}>
          <div
            className="row"
            style={{ textAlign: "center", paddingBottom: "15px" }}
          >
            <div className="col-sm-12 col-lg-6" style={{ paddingTop: "10px" }}>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-default">
                    Date
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <DatePicker
                  selected={this.state.date}
                  onChange={(val, a) => {
                    let d = new Date(val).toISOString().split("T")[0];
                    console.log(d);
                    this.setState({ date: val, formattedDate: d });
                  }}
                />
              </InputGroup>
            </div>

            <div
              className="col-sm-12 col-lg-6"
              style={{ paddingRight: "30px", paddingBottom: "10px" }}
            >
              <Button
                style={{ float: "right" }}
                onClick={() => {
                  this.submit();
                }}
              >
                Save
              </Button>
            </div>
          </div>

          {this.state.studentData != undefined ? (
            <Table
              editable={true}
              paginationDownward={true}
              pagination={true}
              search={true}
              switch={false}
              delete={false}
              editFunc={(oldValue, newValue, row, column) => {
                {
                  if (oldValue != newValue) {
                    if (column.dataField == "points") {
                      let points = newValue - oldValue;
                      deductPoints(row.id, points);
                    }
                    if (column.dataField == "first_name") {
                      updateFirstName(row.id, newValue);
                    }
                    if (column.dataField == "last_name") {
                      updateLastName(row.id, newValue);
                    }
                  }
                }
              }}
              columns={this.studentColumns}
              data={this.state.studentData}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
export default Redeem;
