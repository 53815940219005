import React from "react";

import Table from "./Table";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";

import baseUrl, { authentication } from "../Utils";
import { updateChannel } from "../Utils";

const columns = [
  {
    dataField: "id",
    text: "Id",
    sort: true,
  },
  {
    dataField: "class",
    text: "Class",
    sort: true,
  },
  {
    dataField: "room",
    text: "Room",
    sort: true,
  },
];

class GradeDetails extends React.Component {
  gradeId;
  grade;
  constructor(props) {
    super(props);

    this.state = { channels: [], newChannelOpen: false };
    this.gradeId = this.props.history.location.state.gradeId;
    this.grade = this.props.history.location.state.grade;
    this.setClasses();
  }
  setClasses() {
    fetch(`${baseUrl}/grade/${this.gradeId}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });
        this.setState({ channels: res });
      });
  }

  deleteGrade(classes) {
    if (classes.length > 0) {
      classes = { classes };
      classes = JSON.stringify(classes);
      fetch(`${baseUrl}/classes/delete-bulk/${classes}`, {
        method: 'POST',
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())

        .then((text) => {
          classes = "";

          if (text == "successful") {
            this.setClasses();
            this.gradeTableRef.state.selected = []
          }
        });
    }
  };

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="home">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="grades">Grades</Breadcrumb.Item>
          <Breadcrumb.Item active>{this.grade} grade</Breadcrumb.Item>
        </Breadcrumb>
        {/* <NewChannel isDialogOpen={this.state.newChannelOpen} closeFunction={()=>{this.setState({newChannelOpen:false})}}/> */}
        <div style={{ padding: "25px" }}>
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}></div>
          <Table
            ref={(n) => (this.gradeTableRef = n)}
            delete={true}
            deleteClick={() => {
              this.deleteGrade(this.gradeTableRef.state.selected);
            }}
            switch={true}
            columns={columns}
            data={this.state.channels}
            editFunc={(oldValue, newValue, row, column) => {
              let url = "";
              if (column.dataField == "room") {
                url = "update-room";
              }
              if (column.dataField == "class") {
                url = "update-class-name";
              }
              if (url) {
                fetch(`${baseUrl}/${url}/${row.id}?name=${newValue}`, {
                  headers: {
                    Authorization: authentication,
                  },
                })
                  .then((response) => {
                    return response.json();
                  })
                  .then((res) => {});
              }
            }}
            rowEvents={{
              onClick: (e, row, rowIndex) => {
                console.log(e);
                if (e.type == "click") {
                  //this.props.stateFunc(row.image)
                  this.props.history.push({
                    pathname: "/class-details",
                    state: {
                      class: row.class,
                      room: row.room,
                      classId: row.id,
                      gradeId: this.gradeId,
                      grade: this.grade,
                    },
                  });
                }
              },
            }}
          />
        </div>
      </div>
    );
  }
}
export default GradeDetails;
