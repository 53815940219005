import React from "react";
import Button from "react-bootstrap/Button";
import Table from "./Table";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";
import { Type } from "react-bootstrap-table2-editor";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Form } from "bootstrap-4-react";

class NewLesson extends React.Component {
  info = [];
  constructor(props) {
    super(props);
    this.state = {
      classId: 1,
      date: "",
      studentData: [],
      subcategoryData: undefined,
      selectVal: "d",

      classes: [],
      isDialogOpen: false,
      isSubcategoryDialogOpen: false,
      reload: false,
      tags: [],
    };
    this.getClasses();
    //this.getStudents()
  }
  getClasses() {
    var url2 = new URL("http://ohrhatorah.appsenses.com/getClasses.php");
    fetch(url2)
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });
        this.setState({ classes: res });
      });
  }
  setClass() {
    let formData = new FormData();
    formData.append("action", "get class");
    formData.append("id", this.state.classId);
    var url = "http://ohrhatorah.appsenses.com/students.php";

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.info = [];
        res.map((item) => {
          item.id = parseInt(item.id);
          item.absent = false;
          item.work = false;
          item.late = false;
          item.point = false;
          item.attention = false;
          item.note = "";
          this.info.push(item);
        });
        this.setState({ studentData: res });
      });
  }

  absentFormatter = (cell, row, rowIndex) => {
    let data = this.state.studentData;
    console.log(row);
    data.map((d) => {
      if (d.id == row.id) {
        d.absent = !d.absent;
      }
    });

    this.setState({ studentData: data });
  };
  /*{
        dataField: 'id',
        text: 'Id',
        sort: true
    },*/

  studentColumns = [
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "absent",
      formatter: (cell, row, rowIndex) => {
        return (
          <div
            onClick={(e) => {
              this.info.map((item) => {
                if (item.id == row.id) {
                  item.absent = e.target.checked;
                }
              });
              this.setState({ studentData: this.info });
            }}
          >
            <input value={cell} defaultChecked={cell} type="checkbox" />
          </div>
        );
      },
      text: "Absent",
      sort: true,
      editable: false,
    },
    {
      dataField: "work",
      formatter: (cell, row, rowIndex) => {
        return (
          <div>
            <input
              defaultChecked={cell}
              type="checkbox"
              onChange={(e) => {
                this.info.map((item) => {
                  if (item.id == row.id) {
                    item.work = e.target.checked;
                  }
                });
                this.setState({ studentData: this.info });
              }}
            />
          </div>
        );
      },
      text: "Work",
      sort: true,

      editable: false,
    },
    {
      dataField: "point",
      formatter: (cell, row, rowIndex) => {
        return (
          <div>
            <input
              defaultChecked={cell}
              type="checkbox"
              onChange={(e) => {
                this.info.map((item) => {
                  if (item.id == row.id) {
                    item.point = e.target.checked;
                  }
                });
                this.setState({ studentData: this.info });
              }}
            />
          </div>
        );
      },
      text: "Point",
      sort: true,
      editable: false,
    },
    {
      dataField: "late",
      formatter: (cell, row, rowIndex) => {
        return (
          <div>
            <input
              defaultChecked={cell}
              type="checkbox"
              onChange={(e) => {
                this.info.map((item) => {
                  if (item.id == row.id) {
                    item.late = e.target.checked;
                  }
                });
                this.setState({ studentData: this.info });
              }}
            />
          </div>
        );
      },
      text: "Late",
      sort: true,
      editable: false,
    },
    {
      dataField: "attention",
      formatter: (cell, row, rowIndex) => {
        return (
          <div>
            <input
              type="radio"
              {...(cell == true && { checked: cell })}
              name={rowIndex}
              value="yes"
              onChange={(e) => {
                this.info.map((item) => {
                  if (item.id == row.id) {
                    item.attention = e.target.value;
                  }
                });
                this.setState({ studentData: this.info });
              }}
            />
            <label style={{ paddingLeft: "5px", paddingRight: "8px" }}>
              Yes
            </label>
            <input
              type="radio"
              name={rowIndex}
              value="no"
              onChange={(e) => {
                this.info.map((item) => {
                  if (item.id == row.id) {
                    item.attention = e.target.value;
                  }
                });
                this.setState({ studentData: this.info });
              }}
            />
            <label style={{ paddingLeft: "5px", paddingRight: "8px" }}>
              No
            </label>
            <input
              type="radio"
              name={rowIndex}
              value="maybe"
              onChange={(e) => {
                this.info.map((item) => {
                  if (item.id == row.id) {
                    item.attention = e.target.value;
                  }
                });
                this.setState({ studentData: this.info });
              }}
            />
            <label style={{ paddingLeft: "5px", paddingRight: "8px" }}>
              Maybe
            </label>
          </div>
        );
      },
      text: "Attention",
      sort: true,
      editable: false,
    },
    {
      dataField: "note",
      text: "Notes",
      editor: {
        type: Type.TEXTAREA,
      },
    },
    {
      dataField: "seat",
      text: "Seat",
      sort: true,
    },
  ];

  save = () => {
    let record = {};
    record.class = this.state.classId;
    record.date = this.state.formattedDate;
    record.topic = this.state.topic;
    record.studentData = this.state.studentData;
    let formData = new FormData();
    formData.append("action", "save lesson");
    formData.append("record", JSON.stringify(record));
    // console.log(record)

    fetch("https://www.ohrhatorah.appsenses.com/lesson.php", {
      method: "POST",
      body: formData,
    }).then((response) => {
      this.info = [];
      this.setState({
        selectVal: "d",
        classId: "",
        topic: "",
        studentData: [],
        date: "",
      });
    });
  };

  handleSubcategoryClose = () => {
    this.selected = this.subRef.handleGetSelectedData();
    console.log(this.selected);
    let formData = new FormData();
    formData.append("action", "add to channel");
    formData.append("channel", this.state.channelId);
    formData.append("subs", this.selected);
    fetch(
      "https://cors-anywhere.herokuapp.com/https://www.appsenses.com/jflix/web/updateSubchannel.php",
      {
        method: "POST",
        body: formData, //JSON.stringify(
      }
    ).then((res) => {
      console.log(res);
      this.getClasssSubcategories();
      this.setState({ isSubcategoryDialogOpen: false });
      this.getClasssSubcategories();
    });
  };

  onSelect = (row, isSelected) => {
    //if(isSelected){
    let d = this.state.studentData;
    let newData = [];

    d.map((item, index) => {
      newData.push(JSON.parse(JSON.stringify(item)));
      if (newData[index].id == row.id) {
        newData[index].point = isSelected;
        newData[index].work = isSelected;

        newData[index].attention = isSelected;
      }
    });

    this.setState({ studentData: newData }, () => {
      this.info.map((item) => {
        if (item.id == row.id) {
          item.point = isSelected;
          item.work = isSelected;
          item.attention = isSelected;
        }
      });
    });

    // }
  };

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="lessons">Lessons</Breadcrumb.Item>
          <Breadcrumb.Item active>Add Lesson</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <div style={{ paddingRight: "30px", paddingBottom: "10px" }}>
            <Button
              style={{ float: "right" }}
              onClick={() => {
                this.save();
              }}
            >
              Save
            </Button>
          </div>
          <div className="row" style={{ padding: "15px" }}>
            <div className="col-sm-3" style={{ paddingTop: "10px" }}>
              <Form.CustomSelect
                lg
                mb="3"
                value={this.state.selectVal}
                onChange={(v) => {
                  if (v.target.value != "d") {
                    this.setState(
                      { classId: v.target.value, selectVal: v.target.value },
                      this.setClass
                    );
                  }
                }}
              >
                {this.state.classes.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.class}
                  </option>
                ))}
                <option value="d">Choose Class</option>
                {/*<option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>*/}
              </Form.CustomSelect>
              {/*<InputGroup className="mb-3" >
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroup-sizing-default">Class</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    aria-label="Class "
                                    aria-describedby="inputGroup-sizing-default"
                                    value={this.state.class}
                                    onChange={(v)=>{this.setState({class:v.target.value})}}

                                />
                            </InputGroup>*/}
            </div>
            <div className="col-sm-9" style={{ paddingTop: "10px" }}>
              <InputGroup className="mb-3" style={{ paddingLeft: "10px" }}>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-default">
                    Lesson Topic
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  aria-label="Class "
                  aria-describedby="inputGroup-sizing-default"
                  value={this.state.topic}
                  onChange={(v) => {
                    this.setState({ topic: v.target.value });
                  }}
                />
              </InputGroup>
            </div>
          </div>
          <div className="row">
            <div style={{ textAlign: "center", padding: "20px" }}>
              <InputGroup className="mb-3" style={{ paddingLeft: "10px" }}>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-default">
                    Date
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <DatePicker
                  selected={this.state.date}
                  onChange={(val, a) => {
                    let d = new Date(val).toISOString().split("T")[0];
                    console.log(d);
                    this.setState({ date: val, formattedDate: d });
                  }}
                />
              </InputGroup>
            </div>

            <div></div>
          </div>
          {this.state.studentData.length > 0 && (
            <div style={{ padding: "15px" }}>
              {this.state.studentData != undefined && (
                <Table
                  delete={false}
                  switch={false}
                  editable={true}
                  ref={(n) => (this.tableRef = n)}
                  editFunc={(oldValue, newValue, row, column) => {
                    if (column.text == "Notes") {
                      this.info.map((item, index) => {
                        if (item.id == row.id) {
                          item.note = newValue;
                        }
                      });
                    }
                  }}
                  columns={this.studentColumns}
                  data={this.state.studentData}
                  onSelect={this.onSelect}
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default NewLesson;
