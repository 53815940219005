import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import baseUrl, { authentication } from "../Utils";

export default function AdvancedGradeSettings() {
  const [submiting, setSubmiting] = useState(false);
  const [counterNewGrades, setCounterNewGrades] = useState(0);
  const [gradesRanges, setGradesRanges] = useState({});

  useEffect(() => {
    getGradesRanges();
  }, []);

  const getGradesRanges = () => {
    fetch(`${baseUrl}/get-grade-ranges`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => response.json())
      .then((data) =>
        setGradesRanges(
          data.reduce((pre, cur) => ({ ...pre, [cur.id]: cur }), {})
        )
      )
      .catch((err) => {
        console.error(err);
        alert(err);
      });
  };

  const saveRanges = () => {
    if (submiting || !validateRanges()) return;

    setSubmiting(true);
    let formData = new FormData();
    formData.append(
      "grades",
      JSON.stringify(
        Object.values(gradesRanges).filter((r) => r.isnew || r.edited)
      )
    );
    fetch(`${baseUrl}/save-grade-ranges`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: authentication,
      },
    })
      .then((r) => r.text())
      .then(() => {
        setSubmiting(false);
        getGradesRanges();
      })
      .catch((err) => {
        setSubmiting(false);
        console.error(err);
        alert(err);
      });
  };

  const deleteRange = (id, callback) => {
    if (!+id && id.startsWith("NEW")) {
      return callback();
    }
    fetch(`${baseUrl}/delete-grade-range/${id}`, {
      method: "POST",
      headers: {
        Authorization: authentication,
      },
    })
      .then(callback)
      .then(getGradesRanges)
      .catch((err) => {
        alert(err);
        console.error(err);
      });
  };

  const validateRanges = () => {
    const theRanges = Object.values(gradesRanges);

    if (
      !theRanges.reduce(
        (pre, cur) =>
          !cur.low ||
          !cur.grade_name ||
          theRanges.filter(
            (r) =>
              r.low === cur.low ||
              cur.grade_name.toLowerCase() === r.grade_name.toLowerCase()
          ).length > 1
            ? false
            : pre,
        true
      )
    ) {
      return alert("All the grades must be filled in and fully unique!");
    }
    return true;
  };

  return (
    <>
      <h2 style={{ textAlign: "center", marginBottom: 20 }}>
        Advanced Grade Settings
      </h2>
      <h6 style={{ textAlign: "center", marginBottom: 50 }}>
        Anything under the lowest grade will be F
      </h6>
      <div
        style={{
          width: "80%",
          margin: "auto",
        }}
      >
        {Object.values(gradesRanges)
          .sort((a, b) => (a.low > b.low ? -1 : b.low > a.low ? 1 : 0))
          .map(({ id, grade_name, low }) => (
            <div
              key={id}
              style={{
                borderBottom: "1px dotted gray",
                textAlign: "center",
                width: "80%",
                margin: "auto",
              }}
            >
              If Grade is Above or Equal to: 
              <input
                value={low || ""}
                onChange={(e) =>
                  setGradesRanges({
                    ...gradesRanges,
                    [id]: {
                      ...gradesRanges[id],
                      low: +e.target.value,
                      edited: true,
                    },
                  })
                }
              />
               Grade Description
              <input
                value={grade_name || ""}
                onChange={(e) =>
                  setGradesRanges({
                    ...gradesRanges,
                    [id]: {
                      ...gradesRanges[id],
                      grade_name: e.target.value,
                      edited: true,
                    },
                  })
                }
              />
              <Button
                style={{ margin: 15 }}
                onClick={() =>
                  deleteRange(id, () => {
                    setGradesRanges(
                      Object.values(gradesRanges).reduce(
                        (pre, cur) =>
                          cur.id != id ? { ...pre, [cur.id]: cur } : pre,
                        {}
                      )
                    );
                  })
                }
              >
                Delete Grade
              </Button>
            </div>
          ))}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            onClick={() => {
              const counter = counterNewGrades + 1,
                newId = `NEW${counter}`;
              setGradesRanges({
                ...gradesRanges,
                [newId]: { id: newId, isnew: true },
              });
              setCounterNewGrades(counter);
            }}
          >
            Add Grade
          </Button>
          <Button onClick={saveRanges}>Save Grade Settings</Button>
        </div>
      </div>
    </>
  );
}
