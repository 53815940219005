import React from "react";
import Button from "react-bootstrap/Button";
import Table from "./Table";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";
import { Type } from "react-bootstrap-table2-editor";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Form } from "bootstrap-4-react";
import baseUrl, {
  authentication,
  getClasses,
  getGrades,
  getSubjects,
  getTeachers,
  setNewTestClass,
} from "../Utils";
import { Helmet } from "react-helmet";
import ReactDataGrid from "react-data-grid";
import ReportCard from "./ReportCard";

class Tzedaka extends React.Component {
  info = [];

  constructor(props) {
    super(props);
    this.state = {
      classId: 0,
      date: "",
      studentData: [],
      defaultGradeSelectVal: "default",
      defaultClassSelectVal: "default",
      defaultTeacherSelectVal: "default",
      selectVal: "d",
      grades: [],
      grade: "",
      classes: [],
    };
    getGrades(this);
  }
  studentColumns = [
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      editable: true,
    },
  ];

  save = () => {
    let hasMarks = false;
    let hasNonNumber = false;
    this.state.studentData.map((t) => {
      if (t.amount != "") {
        hasMarks = true;
      }
      if (isNaN(t.amount)) {
        hasNonNumber = true;
      }
    });

    if (this.state.date == "") {
      alert("Please choose a date");
    } else if (this.state.classId == 0) {
      alert("Please choose a class");
    } else if (!hasMarks) {
      alert("Please fill in amounts");
    } else if (hasNonNumber) {
      alert("Number only please");
    } else {
      let record = {};
      record.class = this.state.classId;
      record.date = this.state.formattedDate;

      record.studentData = this.state.studentData;

      let formData = new FormData();

      formData.append("record", JSON.stringify(record));
      // console.log(record)

      fetch(baseUrl + "/new-tzedaka", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())

        .then((text) => {
          if (text == "successful") {
            this.reset();
          } else {
            alert(text);
          }
        });
    }
  };

  reset = () => {
    this.setState({
      classId: 0,
      date: "",
      studentData: [],
      defaultGradeSelectVal: "default",
      defaultClassSelectVal: "default",

      grade: "",
    });
  };

  absentFormatter = (cell, row, rowIndex) => {
    let data = this.state.studentData;

    data.map((d) => {
      if (d.id == row.id) {
        d.absent = !d.absent;
      }
    });

    this.setState({ studentData: data });
  };

  setClass = (classId, context) => {
    let url = `${baseUrl}/really-bare-class/${classId}`;
    fetch(url, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.map((item) => {
          item.id = parseInt(item.id);
          item.amount = "";
          context.info.push(item);
        });

        context.setState({ studentData: res });
      });
  };

  reset = () => {
    this.setState({
      classId: 0,
      date: "",
      topic: "",
      term: "",
      studentData: [],
      defaultGradeSelectVal: "default",
      defaultClassSelectVal: "default",
      defaultTeacherSelectVal: "default",
      aSet: false,
      bSet: false,
      cSet: false,

      teacherId: "",
      selectVal: "d",

      grade: "",

      isDialogOpen: false,
      isSubcategoryDialogOpen: false,
      reload: false,
      subjectId: "",
      defaultSubjectSelectVal: "default",
      defaultTermSelectVal: "default",
      testType: 0,
    });
  };

  render() {
    return (
      <div>
        <Helmet>
          <style>{`
           @media print {
                    .table-bordered td, .table-bordered th {
                    border: 1px solid black !important;
                }}         
                `}</style>
        </Helmet>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>

          <Breadcrumb.Item active>Tzedaka</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <div style={{ paddingRight: "30px", paddingBottom: "10px" }}>
            <Button
              style={{ float: "right" }}
              onClick={() => {
                this.save();
              }}
            >
              Save
            </Button>
          </div>

          <div
            className="row"
            style={{
              textAlign: "center",
              padding: "15px",
              paddingLeft: "25px",
            }}
          >
            <div className="col-sm-4" style={{ paddingTop: "10px" }}>
              <div style={{ textAlign: "center" }}>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroup-sizing-default">
                      Date
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <DatePicker
                    selected={this.state.date}
                    onChange={(val, a) => {
                      let d = new Date(val).toISOString().split("T")[0];
                      console.log(d);
                      this.setState({ date: val, formattedDate: d });
                    }}
                  />
                </InputGroup>
              </div>
            </div>

            <div className="col-sm-8" style={{ paddingTop: "10px" }}>
              <Form.CustomSelect
                lg
                mb="3"
                value={this.state.defaultGradeSelectVal}
                onChange={(v) => {
                  if (v.target.value != "default") {
                    this.setState({
                      grade: v.target.value,
                      defaultGradeSelectVal: v.target.value,
                      defaultClassSelectVal: "default",
                    });
                    getClasses(v.target.value, this);
                    console.log(this.state.grade);
                  }
                }}
              >
                {this.state.grades.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.name}
                  </option>
                ))}
                {this.state.defaultGradeSelectVal == "default" && (
                  <option value="default">Choose Grade</option>
                )}
              </Form.CustomSelect>
            </div>
          </div>

          {this.state.grade != "" && (
            <div
              className="row"
              style={{
                textAlign: "center",
                padding: "15px",
                paddingLeft: "25px",
              }}
            >
              <div className="col-sm-3" style={{ paddingTop: "10px" }}>
                {this.state.grade != "" && (
                  <Form.CustomSelect
                    lg
                    mb="3"
                    value={this.state.defaultClassSelectVal}
                    onChange={(v) => {
                      if (v.target.value != "default") {
                        this.setState(
                          {
                            classId: v.target.value,
                            defaultClassSelectVal: v.target.value,
                          },
                          this.setClass(v.target.value, this)
                        );
                      }
                    }}
                  >
                    {this.state.classes.map((x, y) => (
                      <option value={x.id} key={y}>
                        {x.class}
                      </option>
                    ))}
                    {this.state.defaultClassSelectVal == "default" && (
                      <option value="default">Choose Class</option>
                    )}
                  </Form.CustomSelect>
                )}
              </div>
            </div>
          )}

          {this.state.studentData.length > 0 && (
            <div style={{ padding: "15px" }}>
              <Table
                pagination={false}
                selectable={false}
                delete={false}
                switch={false}
                editable={true}
                editVerify={true}
                editFuncVerify={(oldValue, newValue, row, column, done) => {
                  if (column.text == "Notes") {
                    this.info.map((item, index) => {
                      if (item.id == row.id) {
                        item.note = newValue;
                      }
                    });
                  }
                  if (column.dataField == "mark") {
                    if (isNaN(newValue)) {
                      newValue = "";
                      alert("numbers only");
                      done(false);
                      return false;
                    }
                  }
                }}
                columns={this.studentColumns}
                data={this.state.studentData}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Tzedaka;
