import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Table from "./Table";
import Button from "react-bootstrap/Button";
//import {updateSubcategoryName} from "../Utils";

import baseUrl, {
  authentication,
  deductPoints,
  updateFirstName,
  updateLastName,
  updatePoints,
  getGrades,
  bankAverage,
  addBankAverage,
  roundAverage,
  isOhrHachaim,
} from "../Utils";
import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import { forEach } from "react-bootstrap/cjs/ElementChildren";
import { Helmet } from "react-helmet";

class Poster extends React.Component {
  reset = () => {
    this.setState(
      {
        studentData: [],
        date: "",
        formattedDate: "",
      },
      this.setClass()
    );
  };
  constructor(props) {
    super(props);
    // this.getStudents()
    this.state = {
      studentData: [],
      newSubcategoryOpen: false,

      studentColumns: [
        {
          dataField: "bank_account_number",
          text: "Bank account number",
          sort: true,
          editable: false,
        },
        {
          dataField: "points",
          text: isOhrHachaim ? "YEAR TO DATE" : "Points",
          sort: true,
          row: 1,
          editable: true,
          formatter: (cell, row) => {
            if (row.tests != "" || row.retake != "" || row.marks < -4) {
              return (
                <span
                  className={"strikeout"}
                  style={{
                    width: "100%",
                    height: "100%",
                    background: "black",
                    padding: "0px",
                  }}
                >
                  <strong style={{ color: "white" }}>{cell} </strong>
                </span>
              );
            }
            return <span style={{ fontSize: "1.7em" }}>{cell}</span>;
          },
        },
        {
          dataField: "marks",
          text: "Marks",
          sort: true,
          editable: false,
        },
        {
          dataField: "tests",
          text: "Missing Tests",
          sort: true,
          editable: false,
        },
        {
          dataField: "retake",
          text: "Missing Retakes",
          sort: true,
          editable: false,
        },
        {
          dataField: "percentOfModel",
          text: "%",
          sort: true,
          editable: false,
          formatter: (cell, row) => {
            if (!this.state.studentData) return;
            const { modelStudentMarks, myMarks } =
              this.state.studentData.reduce(
                (pre, cur) =>
                  cur.last_name.endsWith("!!")
                    ? { ...pre, modelStudentMarks: cur.marks }
                    : +cur.id === +row.id
                    ? { ...pre, myMarks: cur.marks }
                    : pre,
                {}
              );
            if (!modelStudentMarks) return;
            return `${((myMarks || 0) / modelStudentMarks || 0) * 100}%`;
          },
        },
      ],
    };
    if (bankAverage) {
      this.state.studentColumns.push({
        dataField: "average",
        text: "Average",
        sort: true,
        editable: false,
        formatter: (cell, row) => {
          return roundAverage(cell);
        },
      });
    }
    this.setClass();
  }

  setClass() {
    fetch(`${baseUrl}/bank`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then(async (res) => {
        res.map((item) => {
          item.id = parseInt(item.id);
          item.points = parseInt(item.points);
          item.tests = "";
          item.missingTests.map((test, index) => {
            if (index != 0) {
              item.tests += " | ";
            }
            item.tests += test.topic;
          });
          item.retake = "";
          item.retakes.map((test, index) => {
            if (index != 0) {
              item.retake += " | ";
            }
            item.retake += test.topic;
          });
        });
        if (bankAverage) {
          res = await addBankAverage(res);
        }
        this.setState({ studentData: res });
      });
  }

  getStudents = () => {
    var url = new URL("http://ohrhatorah.appsenses.com/students.php");
    fetch(url, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        // console.log(res)
        res.forEach((a) => {
          a.id = parseInt(a.id);
          a.points = parseInt(a.points);
        });
        this.setState({ subcategories: res });
      });

    // this.setState({isSubcategoryDialogOpen:true})
  };

  render() {
    return (
      <div>
        {/*@media print {}*/}
        <Helmet>
          <style>{`
           @media print {
                    .table-bordered td, .table-bordered th {
                    border: 1px solid black !important;
                }}         
                `}</style>
        </Helmet>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Rewards</Breadcrumb.Item>
          <Breadcrumb.Item active>Poster</Breadcrumb.Item>
        </Breadcrumb>

        <div style={{ padding: "15px" }}>
          <div
            className="row"
            style={{ textAlign: "center", paddingBottom: "15px" }}
          >
            <div
              className="col-sm-12 col-lg-6"
              style={{ paddingTop: "10px" }}
            ></div>

            <div
              className="col-sm-12 col-lg-6"
              style={{ paddingRight: "30px", paddingBottom: "10px" }}
            >
              {/* <Button style={{float:"right"}} onClick={()=>{this.submit()}}>
                                    Save
                                </Button>*/}
            </div>
          </div>

          {this.state.studentData != undefined ? (
            <Table
              defaultSorted={[
                { dataField: "bank_account_number", order: "asc" },
              ]}
              pagination={false}
              delete={false}
              editFunc={(oldValue, newValue, row, column) => {
                {
                  if (oldValue != newValue) {
                    if (column.dataField == "points") {
                      let points = newValue - oldValue;
                      deductPoints(row.id, points);
                    }
                    if (column.dataField == "first_name") {
                      updateFirstName(row.id, newValue);
                    }
                    if (column.dataField == "last_name") {
                      updateLastName(row.id, newValue);
                    }
                  }
                }
              }}
              columns={this.state.studentColumns}
              data={this.state.studentData}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
export default Poster;
