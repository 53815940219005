import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import React, { useEffect, useRef, useState } from "react";
import baseUrl, { authentication } from "../Utils";
import { useParams } from "react-router-dom";

export default function AdvancedTestSettings() {
  const [loading, setLoading] = useState(false);
  const [subjectRanges, setSubjectRanges] = useState({});
  const { id } = useParams();
  const toBeScrolledTo = useRef(null);

  useEffect(() => {
    getSubjectsRanges();
  }, []);
  useEffect(() => {
    if (!toBeScrolledTo.current) return;
    toBeScrolledTo.current.scrollIntoView();
  }, [subjectRanges[id]]);

  const getSubjectsRanges = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${baseUrl}/get-test-ranges`, {
        headers: {
          Authorization: authentication,
        },
      });
      const data = await res.json();
      setSubjectRanges(
        data.reduce(
          (pre, cur) => ({
            ...pre,
            [cur.subject_id]: {
              name: cur.name,
              ranges: (pre[cur.subject_id]
                ? pre[cur.subject_id].ranges
                : []
              ).concat({
                start: cur.start,
                end: cur.end,
                grade: cur.grade,
                new: !cur.id,
                edited: false,
                subject_id: cur.subject_id,
                id: cur.id,
              }),
            },
          }),
          {}
        )
      );
    } catch (err) {
      console.error(err);
      alert(err);
    }
    setLoading(false);
  };

  const saveRanges = async (subject_id) => {
    if (loading || !validateRanges(subject_id)) return;

    setLoading(true);
    let formData = new FormData();
    formData.append(
      "ranges",
      JSON.stringify(
        subjectRanges[subject_id].ranges.filter((r) => r.new || r.edited)
      )
    );
    try {
      const res = await fetch(`${baseUrl}/save-test-ranges`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: authentication,
        },
      });
      const data = await res.text();
        // getSubjectsRanges();
    } catch (err) {
      console.error(err);
      alert(err);
    }
    setLoading(false);
  };

  const deleteRange = (rangeId, callback) => {
    if (!rangeId) {
      return callback();
    }
    fetch(`${baseUrl}/delete-range/${rangeId}`, {
      method: "POST",
      headers: {
        Authorization: authentication,
      },
    })
      .then(callback)
      // .then(() => getSubjectsRanges())
      .catch((err) => {
        alert(err);
        console.error(err);
      });
  };

  const validateRanges = (subject_id) => {
    const theRanges = subjectRanges[subject_id].ranges;
    const msg = theRanges.reduce((pre, cur) => {
      if (
        isNaN(+cur.start) ||
        isNaN(+cur.end) ||
        !cur.grade ||
        theRanges.filter(
          (r) =>
            (r.start >= cur.start && r.end <= cur.end) || r.grade == cur.grade
        ).length > 1
      ) {
        return "The start and end must be numbers, and grade must be filled in. The ranges and / or grades can NOT overlap or be identical";
      }
    }, "");
    if (msg) alert(msg);
    return !msg;
  };

  const subjectRangesArray = Object.keys(subjectRanges);
  return (
    <>
      <h2 style={{ textAlign: "center", marginBottom: 70 }}>
        Advanced Test Settings
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          width: "80%",
          margin: "auto",
        }}
      >
        {loading ? (
          <Spinner
            style={{
              margin: "auto",
              fontSize: 150,
              width: 40,
              height: 30,
              color: "blue",
            }}
            animation="border"
            role="status"
          />
        ) : subjectRangesArray.length === 0 ? (
          <h3
            style={{
              margin: "auto",
              color: "blue",
            }}
          >
            There are no Subjects with points on Test
          </h3>
        ) : (
          subjectRangesArray.map((subject_id) => {
            const { name, ranges } = subjectRanges[subject_id];
            return (
              <div
                id={subject_id}
                ref={+id === +subject_id ? toBeScrolledTo : null}
                key={subject_id}
                style={{ borderBottom: "5px solid blue" }}
              >
                <h5>{name}</h5>
                <div>
                  {ranges.map((range, index) => (
                    <div
                      key={range.id || index}
                      style={{ border: "solid 3px red" }}
                    >
                      From between
                      <input
                        value={range.start || ""}
                        onChange={(e) => {
                          let newRanges = [...ranges];
                          newRanges[index] = {
                            ...ranges[index],
                            start: e.target.value,
                            edited: true,
                          };
                          setSubjectRanges({
                            ...subjectRanges,
                            [subject_id]: { name, ranges: newRanges },
                          });
                        }}
                      />
                      to
                      <input
                        value={range.end || ""}
                        onChange={(e) => {
                          let newRanges = [...ranges];
                          newRanges[index] = {
                            ...ranges[index],
                            end: e.target.value,
                            edited: true,
                          };
                          setSubjectRanges({
                            ...subjectRanges,
                            [subject_id]: { name, ranges: newRanges },
                          });
                        }}
                      />
                      will get
                      <input
                        value={range.grade || ""}
                        onChange={(e) => {
                          let newRanges = [...ranges];
                          newRanges[index] = {
                            ...ranges[index],
                            grade: e.target.value,
                            edited: true,
                          };
                          setSubjectRanges({
                            ...subjectRanges,
                            [subject_id]: { name, ranges: newRanges },
                          });
                        }}
                      />
                      <Button
                        style={{ margin: 5 }}
                        onClick={() =>
                          deleteRange(range.id, () =>
                            setSubjectRanges({
                              ...subjectRanges,
                              [subject_id]: {
                                name,
                                ranges: ranges.filter((r, i) => i !== index),
                              },
                            })
                          )
                        }
                      >
                        Delete Range
                      </Button>
                    </div>
                  ))}
                </div>
                <Button
                  style={{ margin: "auto" }}
                  onClick={() =>
                    setSubjectRanges({
                      ...subjectRanges,
                      [subject_id]: {
                        name,
                        ranges: ranges.concat({ new: true, subject_id }),
                      },
                    })
                  }
                >
                  Add Range
                </Button>
                <Button
                  style={{ margin: "50px 0 50px 45%" }}
                  onClick={() => saveRanges(subject_id)}
                >
                  Save Test Setting for {name}
                </Button>
              </div>
            );
          })
        )}
      </div>
    </>
  );
}
