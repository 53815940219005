import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import { authentication, createChannel } from "../Utils";
import Select from "react-select";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import baseUrl from "../Utils";

let items = [];

class newStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
      firstName: "",
      lastName: "",
      grade: this.props.gradeId,
      classId: this.props.classId,
    };
  }

  open = () => {
    this.setState({
      isDialogOpen: true,
    });
  };

  componentWillReceiveProps(nextProps) {
    switch (nextProps.isDialogOpen) {
      case true: {
        this.setState({
          isDialogOpen: true,
        });
        break;
      }
      case false: {
        this.setState({
          isDialogOpen: false,
        });
        break;
      }
    }
    //  items=nextProps.possibleGrades

    // for (const [index, value] of nextProps.possibleGrades.entries()) {
    // items.push(<option key={value.id} >{value.name}</option>)
    // }
  }

  uploadNewClass = () => {
    if (this.state.firstName != "" && this.state.lastName != "") {
      fetch(
        `${baseUrl}/student/add/${this.state.grade}/${this.state.classId}/${this.state.firstName}/${this.state.lastName}`,
        {
          headers: {
            Authorization: authentication,
          },
        }
      )
        .then((response) => response.text())

        .then((text) => {
          if (text == "successful") {
            this.setState({ firstName: "", lastName: "" });
            this.props.closeFunction();
          }
        });

      //}
    }
  };

  render() {
    return (
      <Modal
        show={this.state.isDialogOpen}
        onHide={this.handleSubcategoryClose}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>New Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NotificationContainer />
          <div style={{ textAlign: "center" }}>
            <img style={{ width: "50%" }} src={this.state.channelImage}></img>
            <InputGroup
              className="mb-3 col-sm-12"
              style={{ paddingTop: "10px" }}
            >
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroup-sizing-default">
                  First Name
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-label="Channel Name"
                aria-describedby="inputGroup-sizing-default"
                value={this.state.firstName}
                onChange={(v) => {
                  this.setState({ firstName: v.target.value });
                }}
              />
            </InputGroup>
            <InputGroup
              className="mb-3 col-sm-12"
              style={{ paddingTop: "10px" }}
            >
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroup-sizing-default">
                  Last Name
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                aria-label="Channel Name"
                aria-describedby="inputGroup-sizing-default"
                value={this.state.lastName}
                onChange={(v) => {
                  this.setState({ lastName: v.target.value });
                }}
              />
            </InputGroup>
            {/* <InputGroup className="mb-3 col-sm-12" >
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroup-sizing-default">Grade</InputGroup.Text>
                        </InputGroup.Prepend> */}
            {/* <FormControl
                            aria-label="Channel Image"
                            aria-describedby="inputGroup-sizing-default"
                            value={this.state.channelImage}
                            onChange={(v) => {
                                this.setState({channelImage: v.target.value})
                            }}

                        /> */}

            {/* <Form.Control as="select">
        {items}
      <option>1</option>
      <option>2</option>
      <option>3</option>
      <option>4</option>
      <option>5</option>
    </Form.Control> */}
            {/* </Form.Group> */}
            {/* </div> */}
            {/* </InputGroup> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              this.props.closeFunction();
              this.setState({ channelName: "" });
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (NotificationManager.listNotify.length == 0) {
                if (this.state.firstName == "" || this.state.lastName == "") {
                  let e = "";
                  if (this.state.firstName == "") {
                    e = "Please enter a First Name.";
                  }
                  if (this.state.lastName == "") {
                    e = "Please enter a Last Name.";
                  }
                  NotificationManager.error(e, "", 5000, () => {
                    // alert(this.state.grade);
                    // console.log(this.state.grade)
                  });
                } else {
                  this.uploadNewClass();
                }
              }
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

newStudent.propTypes = {};

export default newStudent;
