import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import baseUrl from "../Utils";

class EditUser extends React.Component {
  constructor(props) {
    super(props);
    this.user = JSON.parse(localStorage.getItem('userInfo') || '{}');
    const { username, password, displayName, isAdmin, isActive } = this.user;
    this.state = { username, password, displayName, isAdmin, isActive };
  }

  saveUser() {
    const { username, password, displayName, isAdmin } = this.state;
    if (!username || !password || !displayName) return alert('You must fill all fields!')

    let formData = new FormData();
    formData.append("id", this.user.id);
    formData.append("username", username);
    formData.append("password", password);
    formData.append("displayName", displayName);
    formData.append("isAdmin", isAdmin ? 1 : 0);

    fetch(`${baseUrl}/edit-user`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then(() => {
        localStorage.setItem('userInfo', JSON.stringify({ ...this.state, id: this.user.id }))
        this.props.history.goBack(1)
        // this.props.history.push({
        //   pathname: "/",
        // });
      })
      .catch(e => { console.error(e); alert(e) })
  }

  render() {
    return (
      <div>
        <Modal show={true}>
          <Modal.Header closeButton>
            <Modal.Title>Edit User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ textAlign: "center", padding: "15px" }}>
              <input
                onChange={(e) => {
                  this.setState({ username: e.target.value });
                }}
                type="text"
                value={this.state.username}
                placeholder="Username"
                style={{ width: "75%", padding: "5px" }}
              />
            </div>
            <div style={{ textAlign: "center", padding: "15px" }}>
              <input
                type="password"
                onChange={(e) => {
                  this.setState({ password: e.target.value });
                }}
                placeholder="Password"
                value={this.state.password}
                style={{ width: "75%", padding: "5px" }}
              />
            </div>
            <div style={{ textAlign: "center", padding: "15px" }}>
              <input
                type="text"
                onChange={(e) => {
                  this.setState({ displayName: e.target.value });
                }}
                placeholder="Display Name"
                value={this.state.displayName}
                style={{ width: "75%", padding: "5px" }}
              />
            </div>
            <div style={{ textAlign: "center", padding: "15px" }}>
              <label>Is User Administrator</label>
              <input
                type="checkbox"
                id="isAdmin"
                name="isAdmin"
                checked={this.state.isAdmin}
                onChange={() => this.setState({ isAdmin: !this.state.isAdmin })}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => this.saveUser()}
              variant="primary"
            >
              Save
            </Button>
            <Button
              onClick={() => this.props.history.goBack(1)}
              // onClick={() => this.props.history.push({
              // pathname: "/",
              // })}
              variant="secondary"
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div >
    );
  }
}

export default EditUser;
