import React from "react";
import Button from "react-bootstrap/Button";
import Table from "./Table";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";
import { Type } from "react-bootstrap-table2-editor";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Form } from "bootstrap-4-react";
import Select from "react-select";
import baseUrl, { authentication, getGrades, getTeachers } from "../Utils";
import { FaCheck } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { ThreeSixty } from "@material-ui/icons";

const options = [

  {
    value: 4,
    label: " 4",
  },
  {
    value: 3,
    label: " 3",
  },
  {
    value: 2,
    label: " 2",
  },
  { value: 1, label: " 1" },
  { value: 0, label: " 0" },
];

const optionsWithBonus =  [

  {
    value: 5,
    label: " 5",
  },
  {
    value: 4,
    label: " 4",
  },
  {
    value: 3,
    label: " 3",
  },
  { value: 2, label: " 2" },
  { value: 1, label: " 1" },
];


class SHAGNewBehavior extends React.Component {
  info = [];
  reset = () => {
    this.setState({
      studentData: [],
      defaultGradeSelectVal: "default",
      defaultClassSelectVal: "default",
      defaultTeacherSelectVal: "default",
      classId: "",

      teacherId: "",
      studentPoints: new Map(),

      grade: "",

      classes: [],
    });
  };
  constructor(props) {
    super(props);
    this.state = {
      classId: "",
      date: "",
      studentData: [],
      defaultGradeSelectVal: "default",
      defaultClassSelectVal: "default",
      defaultTeacherSelectVal: "default",
      teachers: [],
      formattedDate: "",

      teacherId: "",
      studentPoints: new Map(),
      grades: [],
      grade: "",
      editing: false,

      classes: [],
      newColumns: [
        {
          dataField: "first_name",
          text: "First Name",
          sort: true,
          editable: false,
        },
        {
          dataField: "last_name",
          text: "Last Name",
          sort: true,
          editable: false,
        },
        {
          dataField: "marks",
          text: "Behavior",
          sort: true,
          editable: true,
          editor: {
            type: Type.SELECT,
            getOptions: (setOptions, { row, column }) => {
              console.log(optionsWithBonus)
              if(this.state.editing && row.late == false){
                return optionsWithBonus;
              }
              return options;           
             },
          },
        },
        {
          dataField: "absent",
          formatter: (cell, row, rowIndex) => {
            if(! this.state.editing){
              return cell;
            }
            if (cell == 1) {
              return (
                <div style={{ textAlign: "center" }}>
                  <FaCheck color="red" size="1.5em" />
                </div>
              );
            }
            return <div>{cell ? 'true' : 'false'}</div>;
          },
          editor: {
            type: Type.CHECKBOX,
            value: "true:false",
          },
          text: "Absent",
          sort: true,
          editable: true,
        },
       {
          dataField: "late",
          formatter: (cell, row, rowIndex) => {
            if(! this.state.editing){
              return cell;
            }
            if (cell == 1) {
              return (
                <div style={{ textAlign: "center" }}>
                  <FaCheck color="red" size="1.5em" />
                </div>
              );
            }
            return <div>{cell ? 'true' : 'false'}</div>;
          },
          editor: {
            type: Type.CHECKBOX,
            value: "true:false",
          },
          text: "Late",
          sort: true,
          editable: true,
        },
        {
          headerStyle: (colum, colIndex) => {
            return { width: "40%", textAlign: "center" };
          },
          dataField: "note",
          text: "Notes",
          editor: {
            type: Type.TEXTAREA,
          },
        },
      ],
    };
    getGrades(this);
    getTeachers(this);
  }
  getClasses(grade) {
    fetch(`${baseUrl}/grade/${grade}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });
        this.setState({ classes: res });
      });
  }
  getOldDay(classId) {
    let date = this.state.formattedDate;
    if (date && date != "" && this.state.teacherId != "" && classId != "") {
      fetch(
        `${baseUrl}/daily-marks/${classId}/${this.state.teacherId}/${date}`,
        {
          headers: {
            Authorization: authentication,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          if (res.length > 0) {
            this.setState({ studentData: res, editing: true });
          } else {
            this.setState({ editing: false }, this.setClass);
          }
        });
    }
  }
  updateNote(i, n) {
    fetch(baseUrl + "/update-student-day-note/" + i + "/" + n, {
      headers: {
        Authorization: authentication,
      },
    }).then(() => {
      this.getOldDay(this.state.classId);
    });
  }

  updateMarks(marksId, oldMarks, newMarks) {
    fetch(
      baseUrl +
        "/update-student-day-marks/" +
        marksId +
        "/" +
        newMarks +
        "/" +
        oldMarks,
      {
        headers: {
          Authorization: authentication,
        },
      }
    ).then(() => {
      this.getOldDay(this.state.classId);
    });
  }
  setAbsent(marksId,oldMarks) {
    fetch(
      baseUrl +
        "/set-student-day-absent/" +
        marksId +
        "/" +
        oldMarks,
      {
        headers: {
          Authorization: authentication,
        },
      }
    ).then(() => {
      this.getOldDay(this.state.classId);
    });
  }
  setNotAbsent(marksId) {
    fetch(
      baseUrl +
        "/set-student-day-notabsent/" +
        marksId,
      {
        headers: {
          Authorization: authentication,
        },
      }
    ).then(() => {
      this.getOldDay(this.state.classId);
    });
  }
  updateLateStatus(marksId,isLate) {
    fetch(
      baseUrl +
        "/update-student-day-lateStatus/" +
        marksId+
        "/"+
        isLate,
      {
        headers: {
          Authorization: authentication,
        },
      }
    ).then(() => {
      this.getOldDay(this.state.classId);
    });
  }
  getTeachers() {
    fetch(baseUrl + "/teachers", {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });
        this.setState({ teachers: res });
      });
  }
  setClass() {
    let formData = new FormData();
    formData.append("action", "get class");
    formData.append("id", this.state.classId);
    var url = "http://ohrhatorah.appsenses.com/students.php";
    url = `${baseUrl}/bare-class/${this.state.classId}`;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.map((item) => {
          item.id = parseInt(item.id);
          item.absent = "";
          item.marks = "";
          item.late = "";
          item.note = "";
          this.info.push(item);
        });
        this.setState({ studentData: res });
      });
  }
  print() {
    let formData = new FormData();
    formData.append("action", "get class");
    formData.append("id", this.state.classId);
    var url = "http://ohrhatorah.appsenses.com/students.php";
    url = `${baseUrl}/bare-class/${this.state.classId}`;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.map((item) => {
          item.id = parseInt(item.id);
          item.absent = "";
          item.marks = "";
          item.late = "";
          item.note = "";
          this.info.push(item);

          this.info.push(item);
        });
        this.setState({ studentData: res }, () => {
          window.print();
        });
      });
  }

  validate = () => {
    if (this.state.formattedDate == "") {
      alert("Please select a date");
    } else if (this.state.teacherId == "") {
      alert("Please select a teacher");
    } else {
      return true;
    }
    return false;
  };
  save = () => {
    if (this.validate()) {
      let obj = {};
      let defaultMarks = 4;
      this.state.studentData.map((s) => {
        s.absent = s.absent === "true" ? true : false;
        s.late = s.late === "true" ? true : false;
      });
      this.state.studentData.forEach((student) => {
        if(student.absent === false){
          //if student is not absent than give him marks
          if (student.marks  === '') {
            student.marks = defaultMarks;
          }else{
            student.marks = parseInt(student.marks)
          }
          if(student.late === false){
            //if not late
            student.marks += 1;
          }
        }else{
          //if student is absent ensure he's not getting any points.
          student.marks = 0;
          student.late = false;
        }
        obj[student.id] = {marks: student.marks, absent: student.absent, late: student.late};

        if (student.note != undefined) {
          obj[student.id].note = student.note;
        }
      });
      let date = this.state.formattedDate;
      let students = {
        students: obj,
        teacher: this.state.teacherId,
        date: date,
        class: this.state.classId,
      };
      students = JSON.stringify(students);
      let formData = new FormData();
      formData.append("data", students);

      fetch(`${baseUrl}/shag-new-daily-marks`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())

        .then((text) => {
          students = "";

          if (text == "successful") {
            this.reset();
          } else {
            alert("error" + text);
          }
        });
    }
  };

  render() {
    return (
      <div>
        <Helmet>
          <style>{`
           @media print {
                    .table-bordered td, .table-bordered th {
                    border: 1px solid black !important;
                }}         
                `}</style>
        </Helmet>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>

          <Breadcrumb.Item active>New Day</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          {!this.state.editing && (
            <div style={{ paddingRight: "30px", paddingBottom: "10px" }}>
              <Button
                style={{ float: "right" }}
                onClick={() => {
                  this.save();
                }}
              >
                Save
              </Button>
              <Button
                style={{ float: "right", marginRight: "15px" }}
                onClick={() => {
                  this.print();
                }}
              >
                Print
              </Button>
            </div>
          )}
          <div
            className="row"
            style={{
              textAlign: "center",
              padding: "15px",
              paddingLeft: "25px",
            }}
          >
            <div
              className="col-sm-12 col-lg-4 col-xl-3"
              style={{ paddingTop: "10px" }}
            >
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-default">
                    Date
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <DatePicker
                  selected={this.state.date}
                  onChange={(val, a) => {
                    let d = new Date(val).toISOString().split("T")[0];
                    console.log(d);
                    this.setState({ date: val, formattedDate: d }, () => {
                      if (this.state.classId != "") {
                        this.getOldDay(this.state.classId);
                      }
                    });
                  }}
                />
              </InputGroup>
            </div>
            <div
              className="col-sm-12 col-lg-4 col-xl-4"
              style={{ paddingTop: "10px" }}
            >
              <Form.CustomSelect
                lg
                mb="3"
                value={this.state.defaultTeacherSelectVal}
                onChange={(v) => {
                  if (v.target.value != "default") {
                    this.setState(
                      {
                        teacherId: v.target.value,
                        defaultTeacherSelectVal: v.target.value,
                      },
                      () => {
                        if (this.state.classId != "") {
                          this.getOldDay(this.state.classId);
                        }
                      }
                    );
                  }
                }}
              >
                {this.state.teachers.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.name}
                  </option>
                ))}
                {this.state.defaultTeacherSelectVal == "default" && (
                  <option value="default">Choose Teacher</option>
                )}
              </Form.CustomSelect>
            </div>
            <div
              className="col-sm-12 col-lg-4 col-xl-5"
              style={{ paddingTop: "10px" }}
            >
              <Form.CustomSelect
                lg
                mb="3"
                value={this.state.defaultGradeSelectVal}
                onChange={(v) => {
                  if (v.target.value != "default") {
                    this.setState({
                      grade: v.target.value,
                      defaultGradeSelectVal: v.target.value,
                      defaultClassSelectVal: "default",
                    });

                    this.getClasses(v.target.value);
                    console.log(this.state.grade);
                  }
                }}
              >
                {this.state.grades.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.name}
                  </option>
                ))}
                {this.state.defaultGradeSelectVal == "default" && (
                  <option value="default">Choose Grade</option>
                )}
              </Form.CustomSelect>
            </div>
          </div>

          <div
            className="row"
            style={{
              textAlign: "center",
              padding: "15px",
              paddingLeft: "25px",
            }}
          >
            <div className="col-sm-6" style={{ paddingTop: "10px" }}>
              {this.state.grade != "" && (
                <Form.CustomSelect
                  lg
                  mb="3"
                  value={this.state.defaultClassSelectVal}
                  onChange={(v) => {
                    if (v.target.value != "default") {
                      this.setState(
                        {
                          classId: v.target.value,
                          defaultClassSelectVal: v.target.value,
                        },
                        this.getOldDay(v.target.value)
                      );
                    }
                  }}
                >
                  {this.state.classes.map((x, y) => (
                    <option value={x.id} key={y}>
                      {x.class}
                    </option>
                  ))}
                  {this.state.defaultClassSelectVal == "default" && (
                    <option value="default">Choose Class</option>
                  )}
                </Form.CustomSelect>
              )}
            </div>
          </div>

          {this.state.studentData.length > 0 && (
            <div style={{ padding: "15px" }}>
              <Table
                pagination={false}
                search={true}
                selectable={false}
                delete={false}
                switch={false}
                editable={true}
                editFunc={(oldValue, newValue, row, column) => {
                  if (column.text == "Notes") {
                    if (this.state.editing) {
                      this.updateNote(row.marks_id, newValue);
                    }

                    this.info.map((item, index) => {
                      if (item.id == row.id) {
                        item.note = newValue;
                      }
                    });
                  }
                  if(this.state.editing){
                    if (column.dataField == "marks") {
                      if(row.absent){
                        alert('you cannot change marks when student is absent');
                        return this.getOldDay(this.state.classId);
                      }
                      return this.updateMarks(row.marks_id, oldValue, newValue);
                    }

                    if(column.dataField == "absent"){
                      //if setting to absent
                      if(newValue == "true"){
                        return this.setAbsent(row.marks_id,row.marks);
                      }else{
                        return this.setNotAbsent(row.marks_id);
                      }
                    }
                    if(column.dataField == "late"){
                      if(row.absent){
                        alert('you cannot change late status when student is absent');
                        return this.getOldDay(this.state.classId);
                      }
                      return this.updateLateStatus(row.marks_id,newValue)
                    }
                  }
                }}
                columns={
                  this.state.newColumns
                }
                data={this.state.studentData}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SHAGNewBehavior;
