import React from "react";

import Table from "./Table";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Collapsible from "react-collapsible";
import { FaChevronDown, FaCheck, FaTimes, FaArrowAltCircleUp, FaArrowAltCircleDown, FaCircle } from "react-icons/fa";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";
import { Type } from "react-bootstrap-table2-editor";
import Chart from "react-google-charts";
import baseUrl, {
  isImreiShefer,
  authentication,
  roundAverage,
  goToGrade,
  updateTest,
  newPhoneNumb,
} from "./../Utils";
import randomColor from "./../Utils";
import TopWarning from "./TopWarning";
import "../App.css";
import ReportCard from "./ReportCard";
import ReactToPrint from "react-to-print";
import Button from "react-bootstrap/Button";
import Dialog from "./dialogs/dialog";
import { Form } from "bootstrap-4-react";
import moment from "moment-timezone";
import { Autorenew, BorderAll, ContactsOutlined } from "@material-ui/icons";
import { Navbar } from "bootstrap-4-react/lib/components";
import 'bootstrap/dist/css/bootstrap.min.css';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

class StudentDetails extends React.Component {
  constructor(props) {
    super(props);
    const urlSplit = window.location.href.split('/');
    const theId = urlSplit[urlSplit.length - 1];
    console.log("🚀 ~ file: StudentDetails.js ~ line 35 ~ StudentDetails ~ constructor ~ theId", theId)

    this.state = {
      theId,
      phones: [],
      infos: null,
      years: [],
      year: "",
      points: "",
      firstName: this.props.history.location.state ? this.props.history.location.state.firstName : '',
      lastName: this.props.history.location.state ? this.props.history.location.state.lastName : '',
      studentId: this.props.history.location.state ? this.props.history.location.state.studentId : theId,
      studentData: [],
      subcategoryData: undefined,
      studentTests: [],
      testChart: [],
      terms: [],
      behavior: "loading",
      warning: "",
      missingTests: [],
      missingRetakes: [],
      notes: [],

      reportcardStudentData: "",
      reportCardLoaded: false,
      reportcardRows: "",
      reportcardColumns: "",
      canteenWorkerOpen: false,
      setSalaryOpen: false,

      term1Comment: "",
      term2Comment: "",
      term3Comment: "",
      canteenTransactions: [],
      canteenJob: [],
      job: [],
      honorRole: [],
    };
    // THIS MUST BE CALLED BEFORE THE OTHERS!
    this.getTheStudent();

    this.getMissingTest();
    //this.getStudentLessons()
    this.getPoints();
    // this.getStudentTests()
    this.getStudentProfile();
    console.log("in constructor");

    this.getMissingRetakes();
    //this.getReportCard()
    //this.getReportCardNew()
    // this.getComments()
    this.getCanteenTransactions();
    this.gradeId = this.props.history.location.state ? this.props.history.location.state.gradeId : '';
    this.classId = this.props.history.location.state ? this.props.history.location.state.classId : '';
    this.grade = this.props.history.location.state ? this.props.history.location.state.grade : '';
    this.class = this.props.history.location.state ? this.props.history.location.state.class : '';
    this.studentId = this.props.history.location.state ? this.props.history.location.state.studentId : theId;
    this.getCanteenJobInfo();
    this.getJobInfo();
    this.getYears();
  }
  getTheStudent() {
    if (!!+this.state.theId && !this.props.history.location.state) {
      fetch(`${baseUrl}/students/${this.state.theId}`, {
        headers: {
          Authorization: authentication,
        },
      }).then(r => r.json()).then(([data]) => {
        console.log("🚀 ~ file: StudentDetails.js ~ line 104 ~ StudentDetails ~ getTheStudent ~ data", data)
        this.setState({
          firstName: data.first_name,
          lastName: data.last_name,
          studentId: data.id,
        });
        this.gradeId = data.grade_id;
        this.classId = data.class_id;
        this.grade = data.grade;
        this.class = data.class;
        this.studentId = data.id;
      })
    }
  }

  studentId;
  studentColumns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,
    },
    {
      dataField: "name",
      text: "Teacher",
      sort: true,
    },
    {
      dataField: "marks",
      text: "Marks",
      sort: true,
    },
    {
      dataField: "absent",
      formatter: (cell, row, rowIndex) => {
        if (cell == true) {
          return (
            <div style={{ textAlign: "center" }}>
              <FaCheck color="red" size="1.5em" />
            </div>
          );
        }
        return <div></div>;
      },
      text: "Absent",
      sort: true,
      editable: false,
    },
    {
      dataField: "no_work",
      formatter: (cell, row, rowIndex) => {
        if (cell == true) {
          return (
            <div style={{ textAlign: "center" }}>
              <FaTimes color="red" size="1.5em" />
            </div>
          );
        } else if (row.absent == 0) {
          return (
            <div style={{ textAlign: "center" }}>
              <FaCheck color="green" size="1.5em" />
            </div>
          );
        }
        return <div></div>;
      },
      text: "Work",
      sort: true,

      editable: false,
    },
    {
      dataField: "assignment",
      formatter: (cell, row, rowIndex) => {
        if (cell == true) {
          return (
            <div style={{ textAlign: "center" }}>
              <FaCheck color="green" size="1.5em" />
            </div>
          );
        }
        return <div></div>;
      },
      text: "Did assignment",
      sort: true,
      editable: false,
    } /*, {
        dataField: "late",
        formatter: (cell, row, rowIndex) => {
            if (cell == false && row.absent == false) {
                return <div style={{textAlign: "center"}}><FaCheck color="green" size="1.5em"/></div>
            }
            ;
            if (row.absent == false) {
                return <div style={{textAlign: "center"}}><FaTimes color="red" size="1.5em"/></div>
            }
            return <div></div>
        },
        text: 'On Time',
        sort: true,
        editable: false

    }, {
        dataField: "attention",

        formatter: (cell, row, rowIndex) => {
            if (cell == true) {
                return <div style={{textAlign: "center"}}><FaCheck color="green" size="1.5em"/></div>
            } else if (cell == false) {
                return <div style={{textAlign: "center"}}><FaTimes color="red" size="1.5em"/></div>
            }
            return <div></div>
        },
        text: 'Attention',
        sort: true

    },*/,
    {
      dataField: "note",
      text: "Notes",
      editor: {
        type: Type.TEXTAREA,
      },
    },
  ];

  canteenColumns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,
      editable: false,
    },
    {
      dataField: "name",
      text: "Item",
      sort: true,
      editable: false,
    },
    {
      dataField: "points",
      text: "Points",
      sort: true,
      editable: false,
    },
  ];
  principalNotesColumns = [
    {
      dataField: "time",
      text: "Time recorded",
      sort: false,
      editable: false,
      headerStyle: (colum, colIndex) => {
        return { width: "25%", textAlign: "center" };
      },
    },
    {
      dataField: "quick",
      text: "Quick Note",
      sort: false,
      editable: false,
    },
    {
      dataField: "large",
      text: "Large Note",
      sort: false,
      editable: true,
      headerStyle: () => {
        return { width: "20%", textAlign: "center" };
      },
    },
    {
      dataField: "comments",
      text: "Comments",
      sort: false,
      editable: true,
      formatter: (comments) => comments.map(({ displayName, date, text }) => <><b><i>{displayName}</i></b> <b>{date}</b><br /> {text}{text && <hr />}</>),
    },
  ];
  missingTestColumns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,
      editable: false,
    },
    {
      dataField: "topic",
      text: "Item",
      sort: true,
      editable: false,
    },
    {
      dataField: "mark",
      text: "Mark",
      sort: true,
      editable: true,
    },
    {
      dataField: "id",
      text: "",
      formatter: (cell, row, rowIndex) => {
        return (
          <button
            onClick={() => {
              this.excuse(cell);
            }}
            value={cell}
          >
            Excuse
          </button>
        );
      },
    },
  ];
  excuse = (id) => {
    // alert("clicked"+id)
    fetch(`${baseUrl}/excuse/${id}/${this.state.studentId}`, {
      headers: {
        Authorization: authentication,
      },
    }).then(() => {
      this.getMissingTest();
    });
  };
  termAverage(term) {
    let subjectMark = 0
    term.subjects.forEach(subject => {
      // subjectsubject.tests.forEach(test =>{
      //   totalMark += test.mark
      // })
      subjectMark += subject.tests.reduce((a, b) => a + b.mark, 0) / subject.tests.length;
    })
    return subjectMark > 0 ? subjectMark / term.subjects.length : 0;
  }

  //for the year average add up all the averages from the terms and then divide it by terms.length
  yearAverage(terms) {
    let totalAverage = 0
    let totalTest = 0
    terms.forEach(term => {
      totalAverage += this.termAverage(term)
    })
    return totalAverage > 0 ? Math.round(totalAverage / terms.length) : 0
  }
  getStudentProfile(year = "") {
    fetch(`${baseUrl}/student-profile/${this.state.studentId}?year=${year}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.setState({
          terms: res.tests,
          behavior: res.behavior,
          warning: this.punishments(res.behavior),
        });
        this.getInfos(year);
      });
  }
  getInfos(year) {
    if (!newPhoneNumb) {
      fetch(`${baseUrl}/phones/${this.state.studentId}?year=${year}`, {
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          this.setState({ phones: res });
          console.log(res);
          this.getStudentLessons(year);
        });
    } else {
      fetch(`${baseUrl}/infos/${this.state.studentId}`, {
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          this.setState({ infos: res });
          console.log(res);
          this.getStudentLessons(year);
        });
    }
  }
  getYears() {
    fetch(`${baseUrl}/years`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.setState({ years: res });
      });
  }
  getFa(value) {
    if (value >= 3) {
      return (
        <FaArrowAltCircleUp color="green" size="1.5em" />
      )
    }
    else if (value >= -2) {
      return (
        <FaCircle color="blue" size="1.5em" />
      )
    }
    else {
      return (
        <FaArrowAltCircleDown color="red" size="1.5em" />
      )
    }
  }

  punishments(marks) {
    let warning = "";
    if (marks != undefined) {
      marks = parseInt(marks);

      // marks=-5
      if (marks < -4) warning = "No privileges";

      if (marks < -9) warning = "Warning";
      if (marks < -14) warning = "Assignment";
    }

    return warning;
  }

  getStudentLessons(year = "") {
    /*let formData = new FormData()
        formData.append("action", "get student lessons")
        formData.append('studentId', this.state.studentId)*/
    var url = "http://ohrhatorah.appsenses.com/lesson.php";
    url = `${baseUrl}/student-lessons/${this.state.studentId}?year=${year}`;

    fetch(url, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        let worked = 0;
        let noWork = 0;
        let absent = 0;
        res.map((item) => {
          item.id = parseInt(item.id);
          if (item.no_work == 1) {
            noWork++;
          } else if (item.absent == 0) {
            worked++;
          } else {
            absent++;
          }
        });
        let data = [
          ["Work", "Amount Of Days"],
          ["Did His Work", worked],
          ["Didn't Do Work", noWork],
          ["Absent", absent],
        ];

        this.setState(
          {
            studentData: res,
            workPie: data,
          },
          () => {
            this.getNotes(year);
          }
        );
      });
  }

  getNotes(year) {
    fetch(`${baseUrl}/get-notes/${this.state.studentId}?year=${year}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        console.log("🚀 ~ file: NOTES~ line 499 ~ StudentDetails ~ .then ~ res", res)
        const noteIdsObj = res.reduce((pre, cur) => ({
          ...pre,
          [cur.note_id]: (pre[cur.note_id] || []).concat({
            ...cur,
            time: moment.utc(cur.note_time).local().format("MMMM Do YYYY"),
            comment: { displayName: cur.displayName || '', date: cur.comment_date ? new Date(cur.comment_date).toLocaleString() : '', text: cur.comment || '' },
          })
        }), {});
        console.log("🚀 ~ file: StudentDetails.js ~ line 507 ~ StudentDetails ~ noteIdsObj ~ noteIdsObj", noteIdsObj)
        const modifiedResults = Object.keys(noteIdsObj).map(noteId => {
          const thisIdsComments = noteIdsObj[noteId];
          return {
            ...thisIdsComments[0],
            id: noteId,
            comments: thisIdsComments.reduce((pre, cur) => pre.concat(cur.comment), []),
          }
        });
        console.log("🚀 ~ file: StudentDetails.js ~ line 514 ~ StudentDetails ~ modifiedResults ~ modifiedResults", modifiedResults)

        this.setState({ notes: modifiedResults }, () => {
          this.getReportCardNew(year);
        });
      });
  }

  getMissingTest() {
    fetch(`${baseUrl}/student-missing-tests/${this.state.studentId}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.setState({ missingTests: res });
      });
  }
  getMissingRetakes() {
    fetch(`${baseUrl}/student-missing-retakes/${this.state.studentId}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.setState({ missingRetakes: res });
      });
  }
  /* getReportCard(){

        fetch(`${baseUrl}/report-card/${this.state.studentId}`).then(response => {
            return response.json();
        }).then(res => {
            this.setState({reportCard: res})
        })
    }*/

  getPoints() {
    fetch(`${baseUrl}/student-points/${this.state.studentId}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.setState({ points: res });
      });
  }
  getCanteenJobInfo() {
    fetch(`${baseUrl}/canteen-worker-info/${this.state.studentId}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.setState({ canteenJob: res });
      });
  }
  getJobInfo() {
    fetch(`${baseUrl}/worker-info/${this.state.studentId}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.setState({ job: res });
      });
  }
  getCanteenTransactions() {
    fetch(`${baseUrl}/canteen-transactions/${this.state.studentId}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.setState({ canteenTransactions: res });
      });
  }
  newCanteenJob = (student, percent) => {
    fetch(`${baseUrl}/new-canteen-job/${student}/${percent}`, {
      headers: {
        Authorization: authentication,
      },
    }).then((res) => {
      this.setState({ canteenWorkerOpen: false });
      this.getCanteenJobInfo();
    });
  };
  newJob = (student, points) => {
    fetch(`${baseUrl}/new-job/${student}/${points}`, {
      headers: {
        Authorization: authentication,
      },
    }).then((res) => {
      this.setState({ setSalaryOpen: false });
      this.getJobInfo();
    });
  };
  deactivateCanteenWorker = () => {
    fetch(
      `${baseUrl}/canteen-worker-deactivate/${this.state.canteenJob[0].id}`,
      {
        headers: {
          Authorization: authentication,
        },
      }
    ).then((res) => {
      this.getCanteenJobInfo();
    });
  };
  deactivateWorker = () => {
    fetch(`${baseUrl}/worker-deactivate/${this.state.job[0].id}`, {
      headers: {
        Authorization: authentication,
      },
    }).then((res) => {
      this.getJobInfo();
    });
  };

  getReportCardNew(year = "") {
    fetch(
      `${baseUrl}/student-report-card/${this.state.studentId}?year=${year}`,
      {
        headers: {
          Authorization: authentication,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.setRowsNew(res);
        //this.setState({reportcardStudentData: res},this.setRows())
      });
  }
  getSubjectMark(termIndex, subjectName) {
    let rows = this.state.reportcardRows;
    let score = '';
    rows.forEach((row) => {
      if (row[0] == subjectName) {
        return score = row[termIndex + 1];
      }
    })
    return score ? score : '';
  }
  setRowsNew(student) {
    let rows = [];
    let columns = [];
    let behaviorRows = [];
    let behaviorColumns = [];
    student.subjects.map((subject, x) => {
      let row = [subject.name];
      subject.terms.map((term, index) => {
        if (x == 0) {
          columns.push(term.term);
        }
        row.push(term.mark);
      });
      rows.push(row);
    });
    student.reportcardRows = rows;
    student.reportcardColumns = columns;

    student.behavior[0].map((behave, x) => {
      behaviorColumns.push(behave.term);
    });
    student.behavior.map((behave, x) => {
      let row = [];
      behave.map((t, i) => {
        if (i == 0) {
          row.push(t.name);
        }
        row.push(t.mark);
      });
      behaviorRows.push(row);
    });

    student.behaviorColumns = behaviorColumns;
    student.behaviorRows = behaviorRows;
    this.setState(
      {
        behaviorRows: behaviorRows,
        behaviorColumns: behaviorColumns,
        honorRole: student.honorRole,
        reportcardStudentData: student,
        reportcardRows: student.reportcardRows,
        reportcardColumns: student.reportcardColumns,
        term1Comment: student.term_1_comment,
        term2Comment: student.term_2_comment,
        term3Comment: student.term_3_comment,
      },
      () => {
        console.log(this.state.reportcardStudentData);
        this.setState({ reportCardLoaded: true });
      }
    );
  }

  getReportCard() {
    fetch(`${baseUrl}/report-card/${this.state.studentId}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.setRows(res);
        //this.setState({reportcardStudentData: res},this.setRows())
      });
  }
  setRows(res) {
    let rows = [];
    let columns = [];
    res.map((subject, x) => {
      let row = [subject.name];
      subject.terms.map((term, index) => {
        if (x == 0) {
          columns.push(term.term);
        }
        row.push(term.mark);
      });
      rows.push(row);
    });

    this.setState({
      reportcardRows: rows,
      reportcardColumns: columns,
      honorRole: res.honorRole,
      reportcardStudentData: res,
    });
  }
  getComments() {
    fetch(`${baseUrl}/reportcard-comments/${this.state.studentId}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.map((comment) => {
          if (comment.term_id == 1) {
            this.setState({ term1Comment: comment });
          }
          if (comment.term_id == 2) {
            this.setState({ term2Comment: comment });
          }
          if (comment.term_id == 3) {
            this.setState({ term3Comment: comment });
          }
        });
        this.setState({ comments: res });
      });
  }

  defaultSorted = [
    {
      dataField: "date", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  newStudentNoteButton = <Button
        onClick={() => {
            this.props.history.push({
            pathname: `/student-note/${this.state.studentId}`,
            state: {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                studentId: this.state.studentId,
            },
            });
        }}
        >
        Student Note
      </Button>

  render() {
    const terms = this.state.terms.map((term, termIndex) => (
      <div key={term.termId} style={{ paddingTop: "15px" }}>
        <Collapsible
          open={true}
          trigger={<CollapsibleText text={term.termName} />}
          style={{ background: "black" }}
        >
          <div
            style={{
              paddingRight: "40px",
              paddingLeft: "40px",
              paddingTop: "10px",
            }}
          >
            <div className="col-sm-3" style={{ paddingTop: "10px" }}>{
              <InputGroup className="mb-3" style={{ paddingLeft: "10px" }}>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-default">
                    Term Average
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  aria-label="Channel Name"
                  aria-describedby="inputGroup-sizing-default"
                  //average per term- term
                  value={roundAverage(this.termAverage(term))}
                ></FormControl>
              </InputGroup>
            } </div>

            {term.subjects.map((subject) => (

              <Collapsible
                key={subject.subjectId}
                open={true}
                trigger={<CollapsibleText text={subject.name} />}
                style={{ background: "black" }}
              >
                <div style={{ paddingBottom: "15px" }}>
                  {this.state.year == "" && (
                    <Button
                      onClick={() => {
                        fetch(
                          `${baseUrl}/catapult/${this.state.studentId}/${term.termId}/${subject.subjectId}`,
                          {
                            headers: {
                              Authorization: authentication,
                            },
                          }
                        );
                      }}
                    >
                      Set as modified on Reportcard
                    </Button>
                  )}
                </div>
                <div className="row">

                  <div className="col-sm-3" style={{ paddingTop: "10px" }}>
                    <InputGroup className="mb-3" style={{ paddingLeft: "10px" }}>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">
                          Subject Average
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        readOnly
                        aria-label="Channel Name"
                        aria-describedby="inputGroup-sizing-default"
                        //average of tests for each subject
                        value={roundAverage(Math.round(subject.tests.reduce((a, b) => a + b.mark, 0) / subject.tests.length))}
                      />
                    </InputGroup>
                  </div>

                  <div className="col-sm-3" style={{ paddingTop: "10px" }}>
                    <InputGroup className="mb-3" style={{ paddingLeft: "10px" }}>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">
                          Average Deviation
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">
                          {this.getFa(Math.round(subject.tests.reduce((a, b) => a + (b.mark - b.average), 0) / subject.tests.length))}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        readOnly
                        aria-label="Channel Name"
                        aria-describedby="inputGroup-sizing-default"
                        value={Math.round(subject.tests.reduce((a, b) => a + (b.mark - b.average), 0) / subject.tests.length)}
                      />

                    </InputGroup>
                  </div>

                  <div className="col-sm-3" style={{ paddingTop: "10px" }}>
                    <InputGroup className="mb-3" style={{ paddingLeft: "10px" }}>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">
                          Report Card Grade
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        readOnly
                        aria-label="Channel Name"
                        aria-describedby="inputGroup-sizing-default"
                        //report card grade for each subject                   
                        value={this.state.reportCardLoaded ? this.getSubjectMark(termIndex, subject.name) : ''}
                      />
                    </InputGroup>
                  </div>
                </div>
                <div>
                  <Table
                    pagination={false}
                    delete={false}
                    editFunc={(oldValue, newValue, row, column) => {
                      {
                        if (this.state.year == "") {
                          updateTest(
                            oldValue,
                            newValue,
                            row,
                            column,
                            row.testId,
                            (r) => {
                              if (r != "successful") alert(r);
                            }
                          );
                        }
                      }
                    }}
                    columns={testColumns}
                    data={subject.tests}
                    rowEvents={{
                      onClick: (e, row, rowIndex) => {
                        if (e.type == "click" && this.state.year == "") {
                          this.props.history.push({
                            pathname: "/test-details",
                            state: {
                              testId: row.testId,
                              topic: row.topic,
                              date: row.date,
                            },
                          });
                        }
                      },
                    }}
                  />
                </div>
              </Collapsible>
            ))}
          </div>
          {/*<div>
                    {this.state.testChart.length>0&&
                    <Chart
                        width={'480px'}
                        height={'200px'}
                        chartType="LineChart"
                        loader={<div>Loading Chart</div>}
                        data={this.state.testChart}
                        options={{
                            hAxis: {
                                title: 'Tests',

                            },
                            vAxis: {
                                title: 'Mark',
                            },chartArea: { width: '60%', height: '70%' },
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />}
                </div>*/}
        </Collapsible>
      </div>
    ));
    return (
      <div>
        <Breadcrumb
          listProps={{
            style: { marginBlockEnd: this.state.warning != "" ? "0em" : "1em" },
          }}
        >
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item
            onClick={() => {
              goToGrade(this, this.grade, this.gradeId);
            }}
          >
            {this.grade}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{this.class}</Breadcrumb.Item>
          <Breadcrumb.Item href="students">Students</Breadcrumb.Item>
          <Breadcrumb.Item active>
            {this.state.firstName} {this.state.lastName}
          </Breadcrumb.Item>
        </Breadcrumb>
        {this.state.year != "" && (
          <div
            style={{
              position: "sticky",
              top: "30%",
              background: "lightgrey",
              textAlign: "center",
              fontSize: "2em",
              color: "white",
            }}
          >
            Viewing Year Of {this.state.year}{" "}
          </div>
        )}
        <Dialog
          name="Canteen Job"
          canteenJob={true}
          isDialogOpen={this.state.canteenWorkerOpen}
          saveFunction={this.newCanteenJob}
          student={this.state.studentId}
          closeFunction={() => {
            this.setState({ canteenWorkerOpen: false });
          }}
        />
        <Dialog
          name="Weekly Salary Job"
          job={true}
          isDialogOpen={this.state.setSalaryOpen}
          saveFunction={this.newJob}
          student={this.state.studentId}
          closeFunction={() => {
            this.setState({ setSalaryOpen: false });
          }}
        />
        {this.state.warning != "" && <TopWarning type={this.state.warning} />}
        {this.state.missingTests.length > 0 && (
          <div
            style={{
              background: "red",
              minHeight: "50px",
              textAlign: "center",
              color: "white",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              {" "}
              <h2>Missing Tests!</h2>
            </div>

            {this.state.missingTests.map((test) => (
              <div
                className="row"
                style={{ textAlign: "left", paddingLeft: "30px" }}
                key={test.id}
              >
                <div className="col-8">
                  <span style={{ fontSize: "26px" }}> Test:</span>{" "}
                  <span style={{ fontSize: "18px" }}>{test.topic} </span>
                </div>
                <div className="col-4">
                  <span style={{ fontSize: "26px", marginLeft: "15px" }}>
                    Date:{" "}
                  </span>
                  <span style={{ fontSize: "18px" }}>{test.date}</span>
                </div>{" "}
              </div>
            ))}
          </div>
        )}
        {this.state.missingRetakes.length > 0 && (
          <div
            style={{
              background: "cadetblue",
              minHeight: "50px",
              textAlign: "center",
              color: "white",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              {" "}
              <h2>Missing Retakes!</h2>
            </div>

            {this.state.missingRetakes.map((test) => (
              <div
                className="row"
                style={{ textAlign: "left", paddingLeft: "30px" }}
                key={test.id}
              >
                <div className="col-6">
                  <span style={{ fontSize: "26px" }}> Test:</span>{" "}
                  <span style={{ fontSize: "18px" }}>{test.topic} </span>
                </div>
                <div className="col-3">
                  <span style={{ fontSize: "26px", marginLeft: "15px" }}>
                    Mark:
                  </span>
                  <span style={{ fontSize: "18px" }}>{test.mark}</span>
                </div>{" "}
                <div className="col-3">
                  <span style={{ fontSize: "26px", marginLeft: "15px" }}>
                    Date:{" "}
                  </span>
                  <span style={{ fontSize: "18px" }}>{test.date}</span>
                </div>{" "}
              </div>
            ))}
          </div>
        )}
        <Tabs 
          defaultActiveKey="tests"
          style={{
           margin: "20px"
          }}
        >
              <Tab eventKey="tests" title="Tests">
              <div>
                {/*<div style={{background:"#ed4337",height:"50px",textAlign:"center",color:"white",display:"flex",alignItems:"center",justifyContent:"center"}}> <h3>Assignment!</h3></div>*/}
                <div style={{ textAlign: "center", padding: "15px" }}>
                <div style={{ paddingBottom: "25px", textAlign: "right" }}>
                    <div
                    style={{
                        textAlign: "left",
                        display: "inline-block",
                        float: "left",
                    }}
                    >
                    <Form.CustomSelect
                        lg
                        mb="3"
                        value={this.state.year == "" ? "current" : this.state.year}
                        onChange={(v) => {
                        let y = "";
                        if (v.target.value != "current") {
                            y = v.target.value;
                        }

                        this.setState({ year: y }, this.getStudentProfile(y));
                        }}
                    >
                        {this.state.years.map((x, y) => (
                        <option value={x} key={x}>
                            {x}
                        </option>
                        ))}
                        this.state.year=="" &&{" "}
                        <option value="current">Current Year</option>
                    </Form.CustomSelect>
                    </div>
                    <span style={{ padding: "5px" }}>

                    <div className="col-sm-3" style={{ paddingTop: "10px" }}>
                        <InputGroup className="mb-3" style={{ paddingLeft: "10px" }}>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroup-sizing-default">
                            Yearly Average
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            readOnly
                            aria-label="Channel Name"
                            aria-describedby="inputGroup-sizing-default"
                            //average of all terms - of all subjects for each year
                            value={roundAverage(this.yearAverage(this.state.terms))}
                        />
                        </InputGroup>
                    </div>
                    {isImreiShefer && (
                        <Button onClick={() => window.open(`${window.location.origin}/idcard?firstName=${this.state.firstName}&lastName=${this.state.lastName}&studentId=${this.state.studentId}`, '_blank', 'height=600, width=1000')} style={{ marginRight: '10px' }}>Print Student ID Card</Button>
                    )}
                    {this.state.year == "" && (this.newStudentNoteButton)}
                    </span>

                    {this.state.canteenJob.length == 0 && (
                    <span style={{ padding: "5px" }}>
                        {this.state.year == "" && (
                        <Button
                            onClick={() => {
                            this.setState({ canteenWorkerOpen: true });
                            }}
                        >
                            New Canteen Job
                        </Button>
                        )}
                    </span>
                    )}
                    {this.state.job.length == 0 && (
                    <span style={{ padding: "5px" }}>
                        {this.state.year == "" && (
                        <Button
                            onClick={() => {
                            this.setState({ setSalaryOpen: true });
                            }}
                        >
                            New Set Salary
                        </Button>
                        )}
                    </span>
                    )}
                </div>
                <div className="row">
                    <div className="col-sm-4" style={{ paddingTop: "10px" }}>
                    <InputGroup className="mb-3" style={{ paddingLeft: "10px" }}>
                        <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">
                            First Name
                        </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                        readOnly
                        aria-label="Channel Name"
                        aria-describedby="inputGroup-sizing-default"
                        value={this.state.firstName}
                        // onChange={(v)=>{this.setState({channelName:v.target.value})}}
                        />
                    </InputGroup>
                    </div>
                    <div
                    className="col-sm-4"
                    style={{ paddingTop: "10px", paddingRight: "10px" }}
                    >
                    <InputGroup className="mb-3" style={{ paddingRight: "10px" }}>
                        <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">
                            Last Name
                        </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                        readOnly
                        aria-label="Channel Image"
                        aria-describedby="inputGroup-sizing-default"
                        value={this.state.lastName}
                        /*onChange={(v) => {
                                        this.setState({channelImage: v.target.value})
                                    }}*/
                        />
                    </InputGroup>
                    </div>
                    <div
                    className="col-sm-2"
                    style={{ paddingTop: "10px", paddingRight: "10px" }}
                    >
                    <InputGroup className="mb-3" style={{ paddingRight: "10px" }}>
                        <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">
                            Behavior
                        </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                        readOnly
                        aria-label="Channel Image"
                        aria-describedby="inputGroup-sizing-default"
                        value={this.state.behavior}
                        /*onChange={(v) => {
                                        this.setState({channelImage: v.target.value})
                                    }}*/
                        />
                    </InputGroup>
                    </div>
                    <div
                    className={`col-sm-2 `}
                    style={{ paddingTop: "10px", paddingRight: "10px" }}
                    >
                    <InputGroup
                        className={`mb-3 ${this.state.missingTests.length > 0 ||
                        this.state.missingRetakes.length > 0
                        ? "cross"
                        : ""
                        }`}
                        style={{ paddingRight: "10px" }}
                    >
                        <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">
                            Points
                        </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                        readOnly
                        aria-label="Channel Image"
                        aria-describedby="inputGroup-sizing-default"
                        value={this.state.points}
                        /*onChange={(v) => {
                                        this.setState({channelImage: v.target.value})
                                    }}*/
                        />
                    </InputGroup>
                    </div>
                </div>
                {this.state.phones.length > 0 &&
                    this.state.phones.map((x, y) => (
                    <div
                        style={{ width: 350, paddingLeft: "10px" }}
                        className={"row"}
                        key={y}
                    >
                        <InputGroup className={`mb-3`} style={{ paddingRight: "0px" }}>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroup-sizing-default">
                            Phone Number
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            readOnly
                            aria-describedby="inputGroup-sizing-default"
                            value={(x.phone)}
                        />
                        </InputGroup>
                    </div>
                    ))}
                {this.state.infos != null &&
                    <div className="row" style={{ padding: "20px", margin: '5px', border: "1px solid black" }}>
                    <div className="col-md-4">
                        <div><h5 >Home Information</h5></div>
                        <div >
                        <p> Address: <span>{this.state.infos.address}</span></p>
                        </div>
                        <div >
                        <p>Home Number: <span>{this.state.infos.home}</span></p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div><h5 >Father's Information</h5></div>
                        <div>
                        <p>Father's Name: <span>{this.state.infos.father_name}</span></p>
                        </div>
                        <div>
                        <p>Father's Cell: <span>{this.state.infos.father_cell}</span></p>
                        </div>
                        <div>
                        <p>Father's Work: <span>{this.state.infos.father_work}</span></p>
                        </div>
                        <div>
                        <p>Father's Email: <span><a href={this.state.infos.father_email}>{this.state.infos.father_email}</a></span></p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div>
                        <h5>Mother's Information</h5>
                        </div>
                        <div>
                        <p>Mother's Cell: <span>{this.state.infos.mother_cell}</span></p>
                        </div>
                        <div>
                        <p>Mother's Work: <span>{this.state.infos.mother_work}</span></p>
                        </div>
                        <div>
                        <p>Mother's Email: <span><a href={this.state.infos.mother_email}>{this.state.infos.mother_email}</a></span></p>
                        </div>
                    </div>
                    </div>
                }
                </div>
             </div>
                <div style={{margin: "0px 15px"}}>
                  {/*tests: */}
                  <div style={{ paddingTop: "15px" }}>
                        {terms}
                  {this.state.studentData && this.state.studentData.length > 0 && (
                          <Collapsible
                            open={true}
                            trigger={<CollapsibleText text="Student's lessons " />}
                            onOpening={() => { }}
                            style={{ background: "black" }}
                          >
                            <div style={{ paddingTop: "10px" }}>
                              {
                                <Table
                                  delete={false}
                                  defaultSorted={this.defaultSorted}
                                  editable={false}
                                  switch={false}
                                  ref={(n) => (this.existingSubcategoryTableRef = n)}
                                  columns={this.studentColumns}
                                  reload={this.state.reload}
                                  data={this.state.studentData}
                                  editFunc={(oldValue, newValue, row, column) => {
                                    if (oldValue != newValue) {
                                      if (column.dataField == "attention") {
                                        let attention;
                                        if (newValue == "yes") {
                                          attention = 1;
                                        }
                                        if (newValue == "no") {
                                          attention = 0;
                                        }

                                        let formData = new FormData();
                                        formData.append("action", "edit attention");
                                        formData.append("id", row.id);
                                        formData.append("attention", attention);
                                        formData.append("studentId", this.state.studentId);
                                        var url =
                                          "http://ohrhatorah.appsenses.com/lesson.php";

                                        fetch(url, {
                                          method: "POST",
                                          body: formData,
                                        })
                                          .then((response) => {
                                            return response.json();
                                          })
                                          .then((res) => {
                                            res.map((item) => {
                                              item.id = parseInt(item.id);
                                            });
                                            this.setState({ studentData: res });
                                          });
                                      } else if (column.dataField == "note") {
                                        let formData = new FormData();
                                        formData.append("action", "edit note");
                                        formData.append("id", row.id);
                                        formData.append("note", newValue);
                                        formData.append("studentId", this.state.studentId);
                                        var url =
                                          "http://ohrhatorah.appsenses.com/lesson.php";

                                        fetch(url, {
                                          method: "POST",
                                          body: formData,
                                        });
                                      }
                                    }
                                  }}
                                /* rowEvents={{
                                                  onClick: (e, row, rowIndex) => {
                                                      console.log(e)
                                                      if (e.type == "click") {
                                                          //this.props.stateFunc(row.image)
                                                          this.props.history.push({
                                                              pathname: '/lesson-details',
                                                              state: {lessonId: row.lesson_id, topic: row.topic, date: row.date}

                                                          })
                                                      }
                                                  }
                                              }}*/
                                />
                              }
                            </div>
                            <div>
                              <Chart
                                width={"500px"}
                                height={"300px"}
                                chartType="PieChart"
                                loader={<div>Loading Chart</div>}
                                data={this.state.workPie}
                                options={{
                                  title: "Daily Work Done",
                                  // Just add this option
                                  is3D: true,
                                }}
                                rootProps={{ "data-testid": "2" }}
                              />
                            </div>
                          </Collapsible>
                        )}
                  </div>
                  {/*missing tests */}
                  {this.state.missingTests && this.state.missingTests.length > 0 && (
                        <div style={{ paddingTop: "15px" }}>
                          <Collapsible
                            open={true}
                            trigger={<CollapsibleText text="Missing Test" />}
                            onOpening={() => { }}
                            style={{ background: "black" }}
                          >
                            <div style={{ paddingTop: "10px" }}>
                              {
                                <Table
                                  delete={false}
                                  defaultSorted={this.defaultSorted}
                                  ref={(n) => (this.existingSubcategoryTableRef = n)}
                                  columns={this.missingTestColumns}
                                  data={this.state.missingTests}
                                  editFunc={(oldValue, newValue, row, column) => {
                                    if (oldValue != newValue) {
                                      newValue = parseInt(newValue);
                                      if (
                                        !(
                                          typeof newValue === "number" && isFinite(newValue)
                                        )
                                      ) {
                                        console.log("not a valid mark");
                                        //   alert("not a valid mark")
                                      } else {
                                        fetch(
                                          `${baseUrl}/update-test/-1/${newValue}/${row.id}/${this.state.studentId}`,
                                          {
                                            headers: {
                                              Authorization: authentication,
                                            },
                                          }
                                        )
                                          .then((response) => {
                                            return response.text();
                                          })
                                          .then((res) => {
                                            this.getMissingTest();
                                          });
                                      }
                                    }
                                  }}
                                />
                              }
                            </div>
                          </Collapsible>
                        </div>
                      )}
                  {/*report cards: */}
                  <div style={{ paddingTop: "15px"}}>
                  {/* <Collapsible open={true} trigger={<CollapsibleText text={"Tests"}/>} style={{background: "black"}}>
                              <div style={{paddingTop: '10px'}}>
                                  <Table delete={false} switch={false} columns={testColumns} data={this.state.studentTests}
                                        rowEvents={{
                                            onClick: (e, row, rowIndex) => {

                                                if (e.type == "click") {

                                                    this.props.history.push({
                                                        pathname: '/test-details',
                                                        state: {testId: row.testId, topic: row.topic, date: row.date}
                                                    })
                                                }
                                            }
                                        }}
                                  />
                              </div>
                              <div>
                                  {this.state.testChart.length > 0 &&
                                  <Chart
                                      width={'480px'}
                                      height={'200px'}
                                      chartType="LineChart"
                                      loader={<div>Loading Chart</div>}
                                      data={this.state.testChart}
                                      options={{
                                          hAxis: {
                                              title: 'Tests',

                                          },
                                          vAxis: {
                                              title: 'Mark',
                                          }, chartArea: {width: '60%', height: '70%'},
                                      }}
                                      rootProps={{'data-testid': '1'}}
                                  />}
                              </div>
                          </Collapsible>*/}
                  <div style={{ marginLeft: "40px" }}>
                    {this.state.reportCardLoaded != false && (
                      <ReactToPrint
                        trigger={() => {
                          // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                          // to the root node of the returned component as it will be overwritten.
                          return <a href="#">Print Report Card</a>;
                        }}
                        content={() => this.reportCardRef}
                      />
                    )}
                    {this.state.reportCardLoaded != false && (
                      <ReportCard
                        ref={(el) => (this.reportCardRef = el)}
                        studentId={this.state.studentId}
                        firstName={this.state.firstName}
                        lastName={this.state.lastName}
                        grade={this.grade}
                        studentData={this.state.reportCardStudentData}
                        term1Comment={
                          this.state.term1Comment ? this.state.term1Comment : ""
                        }
                        term2Comment={
                          this.state.term2Comment ? this.state.term2Comment : ""
                        }
                        term3Comment={
                          this.state.term3Comment ? this.state.term3Comment : ""
                        }
                        rows={this.state.reportcardRows}
                        columns={this.state.reportcardColumns}
                        behaviorRows={this.state.behaviorRows}
                        behaviorColumns={this.state.behaviorColumns}
                        honorRole={[this.state.honorRole]}
                      />
                    )}
                  </div>
                </div>
                </div>
              </Tab>
              <Tab eventKey="behavior" title="Behavior">
              <div>
                {/*<div style={{background:"#ed4337",height:"50px",textAlign:"center",color:"white",display:"flex",alignItems:"center",justifyContent:"center"}}> <h3>Assignment!</h3></div>*/}
                <div style={{ textAlign: "center", padding: "15px" }}>
                <div style={{ paddingBottom: "25px", textAlign: "right" }}>
                    <div
                    style={{
                        textAlign: "left",
                        display: "inline-block",
                        float: "left",
                    }}
                    >
                    <Form.CustomSelect
                        lg
                        mb="3"
                        value={this.state.year == "" ? "current" : this.state.year}
                        onChange={(v) => {
                        let y = "";
                        if (v.target.value != "current") {
                            y = v.target.value;
                        }

                        this.setState({ year: y }, this.getStudentProfile(y));
                        }}
                    >
                        {this.state.years.map((x, y) => (
                        <option value={x} key={x}>
                            {x}
                        </option>
                        ))}
                        this.state.year=="" &&{" "}
                        <option value="current">Current Year</option>
                    </Form.CustomSelect>
                    </div>
                    <span style={{ padding: "5px" }}>

                    <div className="col-sm-3" style={{ paddingTop: "10px" }}>
                        <InputGroup className="mb-3" style={{ paddingLeft: "10px" }}>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroup-sizing-default">
                            Yearly Average
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            readOnly
                            aria-label="Channel Name"
                            aria-describedby="inputGroup-sizing-default"
                            //average of all terms - of all subjects for each year
                            value={roundAverage(this.yearAverage(this.state.terms))}
                        />
                        </InputGroup>
                    </div>
                    {isImreiShefer && (
                        <Button onClick={() => window.open(`${window.location.origin}/idcard?firstName=${this.state.firstName}&lastName=${this.state.lastName}&studentId=${this.state.studentId}`, '_blank', 'height=600, width=1000')} style={{ marginRight: '10px' }}>Print Student ID Card</Button>
                    )}
                    {this.state.year == "" && (this.newStudentNoteButton)}
                    </span>

                    {this.state.canteenJob.length == 0 && (
                    <span style={{ padding: "5px" }}>
                        {this.state.year == "" && (
                        <Button
                            onClick={() => {
                            this.setState({ canteenWorkerOpen: true });
                            }}
                        >
                            New Canteen Job
                        </Button>
                        )}
                    </span>
                    )}
                    {this.state.job.length == 0 && (
                    <span style={{ padding: "5px" }}>
                        {this.state.year == "" && (
                        <Button
                            onClick={() => {
                            this.setState({ setSalaryOpen: true });
                            }}
                        >
                            New Set Salary
                        </Button>
                        )}
                    </span>
                    )}
                </div>
                <div className="row">
                    <div className="col-sm-4" style={{ paddingTop: "10px" }}>
                    <InputGroup className="mb-3" style={{ paddingLeft: "10px" }}>
                        <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">
                            First Name
                        </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                        readOnly
                        aria-label="Channel Name"
                        aria-describedby="inputGroup-sizing-default"
                        value={this.state.firstName}
                        // onChange={(v)=>{this.setState({channelName:v.target.value})}}
                        />
                    </InputGroup>
                    </div>
                    <div
                    className="col-sm-4"
                    style={{ paddingTop: "10px", paddingRight: "10px" }}
                    >
                    <InputGroup className="mb-3" style={{ paddingRight: "10px" }}>
                        <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">
                            Last Name
                        </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                        readOnly
                        aria-label="Channel Image"
                        aria-describedby="inputGroup-sizing-default"
                        value={this.state.lastName}
                        /*onChange={(v) => {
                                        this.setState({channelImage: v.target.value})
                                    }}*/
                        />
                    </InputGroup>
                    </div>
                    <div
                    className="col-sm-2"
                    style={{ paddingTop: "10px", paddingRight: "10px" }}
                    >
                    <InputGroup className="mb-3" style={{ paddingRight: "10px" }}>
                        <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">
                            Behavior
                        </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                        readOnly
                        aria-label="Channel Image"
                        aria-describedby="inputGroup-sizing-default"
                        value={this.state.behavior}
                        /*onChange={(v) => {
                                        this.setState({channelImage: v.target.value})
                                    }}*/
                        />
                    </InputGroup>
                    </div>
                    <div
                    className={`col-sm-2 `}
                    style={{ paddingTop: "10px", paddingRight: "10px" }}
                    >
                    <InputGroup
                        className={`mb-3 ${this.state.missingTests.length > 0 ||
                        this.state.missingRetakes.length > 0
                        ? "cross"
                        : ""
                        }`}
                        style={{ paddingRight: "10px" }}
                    >
                        <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">
                            Points
                        </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                        readOnly
                        aria-label="Channel Image"
                        aria-describedby="inputGroup-sizing-default"
                        value={this.state.points}
                        /*onChange={(v) => {
                                        this.setState({channelImage: v.target.value})
                                    }}*/
                        />
                    </InputGroup>
                    </div>
                </div>
                {this.state.phones.length > 0 &&
                    this.state.phones.map((x, y) => (
                    <div
                        style={{ width: 350, paddingLeft: "10px" }}
                        className={"row"}
                        key={y}
                    >
                        <InputGroup className={`mb-3`} style={{ paddingRight: "0px" }}>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroup-sizing-default">
                            Phone Number
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            readOnly
                            aria-describedby="inputGroup-sizing-default"
                            value={(x.phone)}
                        />
                        </InputGroup>
                    </div>
                    ))}
                {this.state.infos != null &&
                    <div className="row" style={{ padding: "20px", margin: '5px', border: "1px solid black" }}>
                    <div className="col-md-4">
                        <div><h5 >Home Information</h5></div>
                        <div >
                        <p> Address: <span>{this.state.infos.address}</span></p>
                        </div>
                        <div >
                        <p>Home Number: <span>{this.state.infos.home}</span></p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div><h5 >Father's Information</h5></div>
                        <div>
                        <p>Father's Name: <span>{this.state.infos.father_name}</span></p>
                        </div>
                        <div>
                        <p>Father's Cell: <span>{this.state.infos.father_cell}</span></p>
                        </div>
                        <div>
                        <p>Father's Work: <span>{this.state.infos.father_work}</span></p>
                        </div>
                        <div>
                        <p>Father's Email: <span><a href={this.state.infos.father_email}>{this.state.infos.father_email}</a></span></p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div>
                        <h5>Mother's Information</h5>
                        </div>
                        <div>
                        <p>Mother's Cell: <span>{this.state.infos.mother_cell}</span></p>
                        </div>
                        <div>
                        <p>Mother's Work: <span>{this.state.infos.mother_work}</span></p>
                        </div>
                        <div>
                        <p>Mother's Email: <span><a href={this.state.infos.mother_email}>{this.state.infos.mother_email}</a></span></p>
                        </div>
                    </div>
                    </div>
                }
                </div>
      </div>
      <div style={{margin: "0px 15px"}}>
        {/*notes: */}
        {this.state.notes.length > 0 && (
            <div style={{ paddingTop: "15px" }}>
              <Collapsible
                open={true}
                trigger={<CollapsibleText text="Principal Notes" />}
                onOpening={() => { }}
                style={{ background: "black" }}
              >
                <div style={{ paddingTop: "10px" }}>
                  {
                    <Table
                      delete={false}
                      defaultSorted={[
                        {
                          dataField: "time", // if dataField is not match to any column you defined, it will be ignored.
                          order: "desc", // desc or asc
                        },
                      ]}
                      switch={false}
                      ref={(n) => (this.existingSubcategoryTableRef = n)}
                      columns={this.principalNotesColumns}
                      data={this.state.notes}
                      editFunc={(oldValue, newValue, row, column) => {
                        /*  if (oldValue != newValue) {
                                            newValue = parseInt(newValue)
                                            if (!(typeof newValue === 'number' && isFinite(newValue))) {
                                                console.log("not a valid mark")
                                                //   alert("not a valid mark")
                                            } else {
                                                fetch(`${baseUrl}/update-test/-1/${newValue}/${row.id}/${this.state.studentId}`, {
                                                        headers: {
                                                            Authorization: authentication
                                                        }
                                                    }
                                                ).then(response => {
                                                    return response.text();
                                                }).then(res => {
                                                    this.getMissingTest()

                                                })

                                            }
                                        }*/
                      }}
                    />
                  }
                </div>
              </Collapsible>
            </div>
          )}
      </div>
              </Tab>
              <Tab eventKey="bankAccount" title="Bank Account">
              <div>
                {/*<div style={{background:"#ed4337",height:"50px",textAlign:"center",color:"white",display:"flex",alignItems:"center",justifyContent:"center"}}> <h3>Assignment!</h3></div>*/}
                <div style={{ textAlign: "center", padding: "15px" }}>
                <div style={{ paddingBottom: "25px", textAlign: "right" }}>
                    <div
                    style={{
                        textAlign: "left",
                        display: "inline-block",
                        float: "left",
                    }}
                    >
                    <Form.CustomSelect
                        lg
                        mb="3"
                        value={this.state.year == "" ? "current" : this.state.year}
                        onChange={(v) => {
                        let y = "";
                        if (v.target.value != "current") {
                            y = v.target.value;
                        }

                        this.setState({ year: y }, this.getStudentProfile(y));
                        }}
                    >
                        {this.state.years.map((x, y) => (
                        <option value={x} key={x}>
                            {x}
                        </option>
                        ))}
                        this.state.year=="" &&{" "}
                        <option value="current">Current Year</option>
                    </Form.CustomSelect>
                    </div>
                    <span style={{ padding: "5px" }}>

                    <div className="col-sm-3" style={{ paddingTop: "10px" }}>
                        <InputGroup className="mb-3" style={{ paddingLeft: "10px" }}>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroup-sizing-default">
                            Yearly Average
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            readOnly
                            aria-label="Channel Name"
                            aria-describedby="inputGroup-sizing-default"
                            //average of all terms - of all subjects for each year
                            value={roundAverage(this.yearAverage(this.state.terms))}
                        />
                        </InputGroup>
                    </div>
                    {isImreiShefer && (
                        <Button onClick={() => window.open(`${window.location.origin}/idcard?firstName=${this.state.firstName}&lastName=${this.state.lastName}&studentId=${this.state.studentId}`, '_blank', 'height=600, width=1000')} style={{ marginRight: '10px' }}>Print Student ID Card</Button>
                    )}
                    {this.state.year == "" && (this.newStudentNoteButton)}
                    </span>

                    {this.state.canteenJob.length == 0 && (
                    <span style={{ padding: "5px" }}>
                        {this.state.year == "" && (
                        <Button
                            onClick={() => {
                            this.setState({ canteenWorkerOpen: true });
                            }}
                        >
                            New Canteen Job
                        </Button>
                        )}
                    </span>
                    )}
                    {this.state.job.length == 0 && (
                    <span style={{ padding: "5px" }}>
                        {this.state.year == "" && (
                        <Button
                            onClick={() => {
                            this.setState({ setSalaryOpen: true });
                            }}
                        >
                            New Set Salary
                        </Button>
                        )}
                    </span>
                    )}
                </div>
                <div className="row">
                    <div className="col-sm-4" style={{ paddingTop: "10px" }}>
                    <InputGroup className="mb-3" style={{ paddingLeft: "10px" }}>
                        <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">
                            First Name
                        </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                        readOnly
                        aria-label="Channel Name"
                        aria-describedby="inputGroup-sizing-default"
                        value={this.state.firstName}
                        // onChange={(v)=>{this.setState({channelName:v.target.value})}}
                        />
                    </InputGroup>
                    </div>
                    <div
                    className="col-sm-4"
                    style={{ paddingTop: "10px", paddingRight: "10px" }}
                    >
                    <InputGroup className="mb-3" style={{ paddingRight: "10px" }}>
                        <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">
                            Last Name
                        </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                        readOnly
                        aria-label="Channel Image"
                        aria-describedby="inputGroup-sizing-default"
                        value={this.state.lastName}
                        /*onChange={(v) => {
                                        this.setState({channelImage: v.target.value})
                                    }}*/
                        />
                    </InputGroup>
                    </div>
                    <div
                    className="col-sm-2"
                    style={{ paddingTop: "10px", paddingRight: "10px" }}
                    >
                    <InputGroup className="mb-3" style={{ paddingRight: "10px" }}>
                        <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">
                            Behavior
                        </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                        readOnly
                        aria-label="Channel Image"
                        aria-describedby="inputGroup-sizing-default"
                        value={this.state.behavior}
                        /*onChange={(v) => {
                                        this.setState({channelImage: v.target.value})
                                    }}*/
                        />
                    </InputGroup>
                    </div>
                    <div
                    className={`col-sm-2 `}
                    style={{ paddingTop: "10px", paddingRight: "10px" }}
                    >
                    <InputGroup
                        className={`mb-3 ${this.state.missingTests.length > 0 ||
                        this.state.missingRetakes.length > 0
                        ? "cross"
                        : ""
                        }`}
                        style={{ paddingRight: "10px" }}
                    >
                        <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-default">
                            Points
                        </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                        readOnly
                        aria-label="Channel Image"
                        aria-describedby="inputGroup-sizing-default"
                        value={this.state.points}
                        /*onChange={(v) => {
                                        this.setState({channelImage: v.target.value})
                                    }}*/
                        />
                    </InputGroup>
                    </div>
                </div>
                {this.state.phones.length > 0 &&
                    this.state.phones.map((x, y) => (
                    <div
                        style={{ width: 350, paddingLeft: "10px" }}
                        className={"row"}
                        key={y}
                    >
                        <InputGroup className={`mb-3`} style={{ paddingRight: "0px" }}>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroup-sizing-default">
                            Phone Number
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            readOnly
                            aria-describedby="inputGroup-sizing-default"
                            value={(x.phone)}
                        />
                        </InputGroup>
                    </div>
                    ))}
                {this.state.infos != null &&
                    <div className="row" style={{ padding: "20px", margin: '5px', border: "1px solid black" }}>
                    <div className="col-md-4">
                        <div><h5 >Home Information</h5></div>
                        <div >
                        <p> Address: <span>{this.state.infos.address}</span></p>
                        </div>
                        <div >
                        <p>Home Number: <span>{this.state.infos.home}</span></p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div><h5 >Father's Information</h5></div>
                        <div>
                        <p>Father's Name: <span>{this.state.infos.father_name}</span></p>
                        </div>
                        <div>
                        <p>Father's Cell: <span>{this.state.infos.father_cell}</span></p>
                        </div>
                        <div>
                        <p>Father's Work: <span>{this.state.infos.father_work}</span></p>
                        </div>
                        <div>
                        <p>Father's Email: <span><a href={this.state.infos.father_email}>{this.state.infos.father_email}</a></span></p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div>
                        <h5>Mother's Information</h5>
                        </div>
                        <div>
                        <p>Mother's Cell: <span>{this.state.infos.mother_cell}</span></p>
                        </div>
                        <div>
                        <p>Mother's Work: <span>{this.state.infos.mother_work}</span></p>
                        </div>
                        <div>
                        <p>Mother's Email: <span><a href={this.state.infos.mother_email}>{this.state.infos.mother_email}</a></span></p>
                        </div>
                    </div>
                    </div>
                }
                </div>
      </div>
                <div style={{margin: "0px 15px"}}>
                  {this.state.canteenJob.length > 0 && (
                    <div className="row">
                      <div
                        className={`col-sm-6 `}
                        style={{ paddingTop: "10px", paddingRight: "0px" }}
                      >
                        <InputGroup className={`mb-3`} style={{ paddingRight: "0px" }}>
                          <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroup-sizing-default">
                              Canteen Job Percent
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            readOnly
                            aria-describedby="inputGroup-sizing-default"
                            value={this.state.canteenJob[0].percent}
                          />
                        </InputGroup>
                      </div>
                      <div
                        style={{
                          paddingLeft: "0px",
                          alignSelf: "center",
                          textAlign: "left",
                        }}
                        className={`col-sm-1 `}
                    >
                      <FaTimes
                        color="red"
                        size="1.5em"
                        onClick={this.deactivateCanteenWorker}
                      ></FaTimes>
                    </div>
                  </div>
                )}
                {this.state.job.length > 0 && (
                  <div className="row">
                    <div
                      className={`col-sm-6 `}
                      style={{ paddingTop: "10px", paddingRight: "0px" }}
                    >
                      <InputGroup className={`mb-3`} style={{ paddingRight: "0px" }}>
                        <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroup-sizing-default">
                            Job Weekly Salary
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          readOnly
                          aria-describedby="inputGroup-sizing-default"
                          value={this.state.job[0].points}
                        />
                      </InputGroup>
                    </div>
                    <div
                      style={{
                        paddingLeft: "0px",
                        alignSelf: "center",
                        textAlign: "left",
                      }}
                      className={`col-sm-1 `}
                    >
                      <FaTimes
                        color="red"
                        size="1.5em"
                        onClick={this.deactivateWorker}
                      ></FaTimes>
                    </div>
                  </div>
                )}
                {this.state.canteenTransactions &&
                  this.state.canteenTransactions.length > 0 && (
                    <div style={{ paddingTop: "15px" }}>
                    <Collapsible
                      open={true}
                      trigger={<CollapsibleText text="Canteen" />}
                      onOpening={() => { }}
                      style={{ background: "black" }}
                    >
                      <div style={{ paddingTop: "10px" }}>
                        {
                          <Table
                            delete={false}
                            defaultSorted={this.defaultSorted}
                            ref={(n) => (this.existingSubcategoryTableRef = n)}
                            columns={this.canteenColumns}
                            data={this.state.canteenTransactions}
                          />
                        }
                      </div>
                    </Collapsible>
                  </div>
                )}
          </div>
                  </Tab>
            </Tabs>
            
        </div>
    );
  }
}

const testColumns = [
  {
    dataField: "date",
    text: "Date",
    sort: true,
  },
  {
    dataField: "topic",
    text: "topic",
    sort: true,
  },
  {
    dataField: "mark",
    text: "Mark",
    sort: true,
    editable: true,
  },
  {
    dataField: "average",
    text: "Deviation",
    sort: true,
    formatter: (cell, row) => {
      cell = Math.round(row.mark - cell);
      if (cell >= 3) {
        return (
          <div>
            <span style={{ paddingRight: "20px" }}><FaArrowAltCircleUp color="green" size="1.5em" /></span><span>{cell}</span>
          </div>
        )
      }
      else if (cell >= -2) {
        return (
          <div>
            <span style={{ paddingRight: "20px" }}><FaCircle color="blue" size="1.5em" /></span><span>{cell}</span>
          </div>
        )
      }
      else {
        return (
          <div>
            <span style={{ paddingRight: "20px" }}><FaArrowAltCircleDown color="red" size="1.5em" /></span><span>{cell}</span>
          </div>
        )
      }
    }
  },
  {
    dataField: "retake",
    text: "Retake",
    sort: true,
    editable: true,
  },
  {
    dataField: "note",
    text: "Notes",
    editable: false,
    editor: {
      type: Type.TEXTAREA,
    },
  },
];

const CollapsibleText = (props) => {
  {
    /*className='col-sm-3 col-lg-2'*/
  }
  {
    /*className='col-sm-9 col-lg-10'*/
  }
  return (
    <span style={{ paddingLeft: "15px" }} className="row">
      <span style={{ width: "150px" }}> {props.text}</span>{" "}
      <span>
        <FaChevronDown />
      </span>
    </span>
  );
};
export default StudentDetails;

/*[
                            ['v', 'mark'],
                            [0,95],
                            [1,60],
                            [2, 23],
                            [3, 17],
                            [4, 18],
                            [5, 9],
                            [6, 11],
                            [7, 27],
                            [8, 33],
                            [9, 40],
                            [10, 32],
                            [11, 35],
                        ]*/
