import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Table from "./Table";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

import {
  authentication,
  deductPoints,
  updateFirstName,
  updateLastName,
  updatePoints,
  newPhoneNumb
} from "../Utils";
import Chart from "react-google-charts";
import NewStudent from "./newStudent";
import CsvUploadStudent from "./csvUploadStudent";
import baseUrl from "../Utils";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";

class ClassDetails extends React.Component {
  gradeId;
  classId;

  constructor(props) {
    super(props);
    // this.getStudents()
    this.state = {
      class: this.props.history.location.state.class,
      room: this.props.history.location.state.room,
      classId: this.props.history.location.state.classId,
      studentData: [],
      newBulkStudentOpen: false,
      newStudentOpen: false,
    };
    this.gradeId = this.props.history.location.state.gradeId;
    this.classId = this.props.history.location.state.classId;
    this.grade = this.props.history.location.state.grade;

    this.setClass();
  }

  studentColumns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
    },
    // ,{
    //     dataField: 'absent',
    //     text: 'Days Absent',
    //     sort: true
    // }
    {
      dataField: "points",
      text: "Points",
      sort: true,
    },
  ];

  setClass() {
    let url = `${baseUrl}/class/${this.classId}`;
    fetch(url, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        let chart = [["Name", "Work", "Work Not Done", "Absent"]];
        // let numberOfTests=0
        //let testChart=["Name"]
        res.map((item) => {
          item.id = parseInt(item.id);
          item.work = parseInt(item.work);
          item.absent = parseInt(item.absent);
          item.no_work = parseInt(item.no_work);
          item.average = parseInt(item.average);
          if (item.points != undefined) {
            item.points = parseInt(item.points);
          }
          //     if(item.tests!=null){
          //     item.tests=item.tests.split(",")
          //     if(item.tests.length>numberOfTests){
          //         numberOfTests=item.tests.length
          //     }

          //     item.tests.map((test,index)=>{
          //         item.tests[index]=parseInt(test)
          //     })
          // }
          chart.push([item.last_name, item.work, item.no_work, item.absent]);
        });
        // for (let i = 0; i < numberOfTests; i++) {
        //     let testNum=i+1
        //     testChart.push("Test "+testNum)
        // }
        // let newTestChart=[]
        //     newTestChart.push(testChart)

        // res.map((item)=>{
        //     let kidInfo=[item.last_name];

        //     let missingTests=numberOfTests-item.tests.length
        //     for (let x = 0; x <missingTests ; x++) {
        //         item.tests.push(0)

        //     }

        //     item.tests.map((test)=>{
        //         kidInfo.push(test)

        //     })
        //     newTestChart.push(kidInfo)
        // })
        // testChart=newTestChart

        // console.log(testChart)
        // console.log(chart)
        // set state ,testChart:newTestChart
        this.setState({ studentData: res, chart: chart });
      });
  }

  getStudents = () => {
    fetch(`${baseUrl}/class/${this.classId}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        // console.log(res)
        res.forEach((a) => {
          a.id = parseInt(a.id);
          if (a.points != undefined) {
            a.points = parseInt(a.points);
          }
        });
        this.setState({ subcategories: res });
      });

    // this.setState({isSubcategoryDialogOpen:true})
  };
  deleteStudents = (students) => {
    if (students.length > 0) {
      students = { students: students };
      students = JSON.stringify(students);
      fetch(`${baseUrl}/students/delete-bulk/${students}`, {
        method: 'POST',
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())

        .then((text) => {
          students = "";

          if (text == "successful") {
            this.setClass();
          }
        });
    }
  };
  go = () => {
    this.props.history.push({
      pathname: "/grade-details",
      state: {
        grade: this.props.history.location.state.grade,
        gradeId: this.props.history.location.state.gradeId,
      },
    });
  };

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="Home">Classes</Breadcrumb.Item>
          <Breadcrumb.Item onClick={this.go}>
            {this.props.history.location.state.grade}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{this.state.class}</Breadcrumb.Item>
        </Breadcrumb>

        <NewStudent
          gradeId={this.gradeId}
          classId={this.state.classId}
          isDialogOpen={this.state.newStudentOpen}
          closeFunction={() => {
            this.setClass();
            this.setState({ newStudentOpen: false });
          }}
        />
        <CsvUploadStudent
          gradeId={this.gradeId}
          classId={this.state.classId}
          isDialogOpen={this.state.newBulkStudentOpen}
          closeFunction={() => {
            this.setClass();
            this.setState({ newBulkStudentOpen: false });
          }}
        />

        <div
          style={{
            paddingTop: "15px",
            paddingRight: "25px",
            paddingLeft: "25px",
          }}
        >
          <div style={{ textAlign: "right", paddingBottom: "10px" }}>
            <div
              style={{
                background: "#efefef",
                paddingRight: "15px",
                paddingLeft: "15px",
                display: "inline-block",
                textAlign: "right",
              }}
            >
              <Button
                onClick={() => {
                  this.setState({ newStudentOpen: true });
                }}
              >
                New Student
              </Button>
              {newPhoneNumb && <Link to="/files/students_template.xlsx" target="_blank" download>
                <Button style={{ margin: "10px" }}>
                  Download Template              
                </Button>
              </Link>}
              <Button
                style={{ margin: "10px" }}
                onClick={() => {
                  this.setState({ newBulkStudentOpen: true });
                }}
              >
                Bulk upload Students from csv
              </Button>
            </div>
          </div>
          <div
            style={{ padding: "10px", paddingBottom: "20px" }}
            className="row"
          >
            <div className="col-sm-6" style={{ paddingTop: "10px" }}>
              <InputGroup className="mb-3" style={{ paddingLeft: "10px" }}>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-default">
                    Class Name
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  aria-label="Class Name"
                  aria-describedby="inputGroup-sizing-default"
                  value={this.state.class}
                  // onChange={(v)=>{this.setState({channelName:v.target.value})}}
                />
              </InputGroup>
            </div>
            <div
              className="col-sm-6"
              style={{ paddingTop: "10px", paddingRight: "10px" }}
            >
              <InputGroup className="mb-3" style={{ paddingRight: "10px" }}>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-default">
                    Room
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  aria-label="Channel Image"
                  aria-describedby="inputGroup-sizing-default"
                  value={this.state.room}
                  /*onChange={(v) => {
                                    this.setState({channelImage: v.target.value})
                                }}*/
                />
              </InputGroup>
            </div>
          </div>

          {this.state.studentData != undefined ? (
            <Table
              ref={(n) => (this.studentTableRef = n)}
              deleteClick={() => {
                this.deleteStudents(this.studentTableRef.state.selected);
              }}
              paginationDownward={true}
              delete={true}
              editFunc={(oldValue, newValue, row, column) => {
                {
                  if (oldValue != newValue) {
                    if (column.dataField == "points") {
                      let points = newValue - oldValue;
                      deductPoints(row.id, points);
                    }
                    if (column.dataField == "first_name") {
                      updateFirstName(row.id, newValue);
                    }
                    if (column.dataField == "last_name") {
                      updateLastName(row.id, newValue);
                    }
                  }
                }
              }}
              columns={this.studentColumns}
              data={this.state.studentData}
              rowEvents={{
                onClick: (e, row, rowIndex) => {
                  console.log(e);
                  if (e.type == "click") {
                    //this.props.stateFunc(row.image)
                    this.props.history.push({
                      pathname: `/student-details/${row.id}`,
                      state: {
                        class: row.class,
                        classId: this.classId,
                        gradeId: this.gradeId,
                        grade: this.grade,
                        studentId: row.id,
                        firstName: row.first_name,
                        lastName: row.last_name,
                      },
                    });
                  }
                },
              }}
            />
          ) : (
            ""
          )}
          <div>
            {this.state.chart &&
              this.state.chart != "" &&
              this.state.chart.length > 1 && (
                <div style={{ paddingBottom: "10px" }}>
                  <Chart
                    width={"100%"}
                    height={"600px"}
                    chartType="BarChart"
                    loader={<div>Loading Chart</div>}
                    data={this.state.chart}
                    options={{
                      title: "Behavior",
                      orientation: "horizontal",
                      hAxis: {
                        slantedTextAngle: 90,
                      },

                      // Material design options
                      chart: {
                        title: "Behavior",
                        /*subtitle: 'Sales, Expenses, and Profit: 2014-2017',*/
                      },
                      chartArea: { width: "80%", height: "60%" },
                    }}
                    // For tests
                    rootProps={{ "data-testid": "2" }}
                  />
                </div>
              )}
            {/*<div style={{paddingBottom:"10px"}}>*/}

            {/*    <Chart*/}
            {/*        width={'100%'}*/}
            {/*        height={'600px'}*/}
            {/*        chartType="BarChart"*/}
            {/*        loader={<div>Loading Chart</div>}*/}
            {/*        data={this.state.testChart}*/}
            {/*        options={{*/}
            {/*            title: 'Test Marks',*/}
            {/*            orientation:"horizontal",*/}
            {/*            hAxis:{*/}
            {/*                slantedTextAngle:90*/}
            {/*            },*/}

            {/*            // Material design options*/}
            {/*            chart: {*/}
            {/*                title: 'Test Marks',*/}
            {/*                /*subtitle: 'Sales, Expenses, and Profit: 2014-2017',*/}
            {/*            },chartArea: { width: '80%', height: '60%' },*/}
            {/*        }}*/}
            {/*        // For tests*/}
            {/*        rootProps={{ 'data-testid': '2' }}*/}
            {/*    />*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    );
  }
}

export default ClassDetails;
