import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Table from "./Table";
import Button from "react-bootstrap/Button";

import baseUrl, {
  authentication,
  deductPoints,
  updateFirstName,
  updateLastName,
  updatePoints,
} from "../Utils";
import Select from "react-select";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import { Type } from "react-bootstrap-table2-editor";

class Redeem extends React.Component {
  reset = () => {
    this.setState({
      studentData: [],
      date: "",
      formattedDate: "",
    });
  };

  constructor(props) {
    super(props);

    this.state = {
      barcodes: "",
      date: "",
      formattedDate: "",
    };
  }

  validate = () => {
    if (
      !this.state.formattedDate ||
      this.state.formattedDate == "" ||
      this.state.formattedDate.length == 0
    ) {
      alert("Please Select A Date.");
      return false;
    }
    return true;
  };

  submit = () => {
    if (!this.uploading) {
      this.uploading = true;

      if (this.validate()) {
        let a = this.state.barcodes.split("\n");
        let data = [];
        let student = "";
        let code;

        for (let x = 0; x < a.length; x++) {
          code = a[x];
          if (code.startsWith("ac")) {
            if (student) {
              data.push(student);
            }
            student = { account: code.match(/ac([\d]+).*/)[1], items: [] };
          } else if (code.startsWith("item")) {
            if (student) {
              student.items.push(code.match(/item([\d]+).*/)[1]);
            }
          }
        }
        if (student) {
          data.push(student);
        }
        data = { data: data, date: this.state.formattedDate };
        data = JSON.stringify(data);
        let formData = new FormData();
        formData.append("data", data);
        fetch(`${baseUrl}/barcodes-upload`, {
          method: "POST",
          body: formData,
          headers: {
            Authorization: authentication,
          },
        })
          .then((response) => response.text())

          .then((text) => {
            if (text == "successful") {
              alert(text);
              this.setState({ barcodes: "" }, () => {
                this.uploading = false;
              });
            } else {
              console.log(text);
            }
          });
      }
    }
  };
  barcodes = "";
  uploading = false;

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Rewards</Breadcrumb.Item>
          <Breadcrumb.Item active>Upload barcodes</Breadcrumb.Item>
        </Breadcrumb>

        <div style={{ padding: "15px" }}>
          <div
            className="row"
            style={{ textAlign: "center", paddingBottom: "15px" }}
          >
            <div className="col-sm-12 col-lg-4"></div>
            <div className="col-sm-12 col-lg-4">
              <div style={{ width: 250 }}>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroup-sizing-default">
                      Date
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <DatePicker
                    selected={this.state.date}
                    onChange={(val, a) => {
                      let d = new Date(val).toISOString().split("T")[0];
                      console.log(d);
                      this.setState({ date: val, formattedDate: d });
                    }}
                  />
                </InputGroup>
              </div>
            </div>

            <div className="col-sm-12 col-lg-4"></div>
          </div>
          <div className={"row"}>
            <div className="col-sm-12 col-lg-4"></div>
            <div className="col-sm-12 col-lg-4">
              <textarea
                placeholder={
                  "Click here and follow scanner directions to upload barcodes from the upc scanner"
                }
                value={this.state.barcodes}
                onChange={(v) => {
                  this.setState({ barcodes: v.target.value });
                }}
                style={{ height: 400 }}
              ></textarea>
            </div>
            <div className="col-sm-12 col-lg-4"></div>
          </div>

          <div style={{ marginTop: 25 }} className={"row"}>
            <div className="col-sm-12 col-lg-4"></div>
            <div
              className="col-sm-12 col-lg-4"
              style={{ paddingRight: "30px", paddingBottom: "10px" }}
            >
              <Button
                style={{ marginLeft: 200 }}
                onClick={() => {
                  this.submit();
                }}
              >
                Save
              </Button>
            </div>
            <div className="col-sm-12 col-lg-4"></div>
          </div>
        </div>
      </div>
    );
  }
}
export default Redeem;
