import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Table from "./Table";
import { Type } from "react-bootstrap-table2-editor";
import Button from "react-bootstrap/Button";
import baseUrl, {
  authentication,
} from "../Utils";
 import CsvUpdateStudents from "./csvUpdateStudents";

class PhoneNumbers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            studentData : []
        };
        this.phoneTypes = ['home','father_cell','father_work','mother_cell','mother_work'];
        this.infoTypes = ['father_name','father_email','mother_name','mother_email'];
        this.studentColumns = [  
            {
              dataField: "id",
              text: "Id",
              sort: true,
              editable: false
            },
            {
              dataField: "first_name",
              text: "First Name",
              sort: true,
              editable: false
            },
            {
              dataField: "last_name",
              text: "Last Name",
              sort: true,
              editable: false
            },
            {
                dataField: "class",
                text: "Class",
                sort: true,
                editable: false
              },
            {
              dataField: "address",
              text: "Address",
              sort: true,
              editable: false
            },            
            { 
                text: "Home",
                dataField: 'home',
                sort: true,
                editable: true,
            },
            { 
                text: "Father's Name",
                dataField: 'father_name',
                sort: true,
                editable: true,
            },
            { 
                text: "Father's Cell",
                dataField: 'father_cell',
                sort: true,
                editable: true,
            },
            { 
                text: "Father's Work",
                dataField: 'father_work',
                sort: true,
                editable: true,
            },
            { 
                text: "Father's Email",
                dataField: 'father_email',
                sort: true,
                editable: true,
            },
            { 
                text: "Mother's Name",
                dataField: 'mother_name',
                sort: true,
                editable: true,
            },
            { 
                text: "Mother's Cell",
                dataField: 'mother_cell',
                sort: true,
                editable: true,
            },
            { 
                text: "Mother's Work",
                dataField: 'mother_work',
                sort: true,
                editable: true,
            },
            { 
                text: "Mother's Email",
                dataField: 'mother_email',
                sort: true,
                editable: true,
            },
            {
                dataField: "primary_number",
                formatter: (cell, row, rowIndex) => {
                  if (cell != '') {
                    return (
                      <div>{  this.primaryOptions.find(option => option.value === cell)? 
                              this.primaryOptions.find(option => option.value === cell).label
                              :cell}
                      </div>
                    );
                  }
                }, 
                editable: true,
                editor: {
                  type: Type.SELECT,
                  getOptions: (setOptions, { row, column }) => {
                    return this.primaryOptions;           
                   },
                },
                text: "Primary Number",
                sort: true,
              }
          ];
        this.primaryOptions = [
            { label: "Home", value: "home" },
            { label: "Father's Cell", value: "father_cell" },
            { label: "Father's Work", value: "father_work" },
            { label: "Mother's Cell", value: "mother_cell" },
            { label: "Mother's Work", value: "mother_work" },
        ];
        this.getStudents();    
    }
    getStudents(){
        let url = `${baseUrl}/phone-numbers`;
        fetch(url, {
          headers: {
            Authorization: authentication,
          },
        })
        .then((response) => {
            return response.json();
        })
        .then((res) => {
            this.setState({studentData:res});
        })
    }
    updateInfo(student_id, data_name, info,infoType){
        let url = `${baseUrl}/update-info`;
        let newInfo = {
            id : student_id,
            data_name: data_name,
            info : info,
            infoType:infoType
        }
        info = JSON.stringify(newInfo);
        let formData = new FormData();
        formData.append("data", info);
        fetch(url, {
            method: "POST",
            body: formData,
            headers: {
              Authorization: authentication,
            },
        })
        .then((response) => {
              return response.json();
        })
        .then((res) => {
              console.log(res)
        })
    }
    updatePrimary(student_id, type){
        let phone_type = type;
        let url = `${baseUrl}/update-primary-number/${student_id}/${phone_type}`;
        fetch(url, {
            method: "POST",
            headers: {
              Authorization: authentication,
            },
        })
        .then((response) => {
              return response.json();
        })
        .then((res) => {
              console.log(res)
        })
    }
    render() {
        return (
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Phone Numbers</Breadcrumb.Item>
                </Breadcrumb>
                <CsvUpdateStudents
                    isDialogOpen={this.state.newBulkStudentOpen}
                    closeFunction={() => {
                        this.getStudents();
                        this.setState({ newBulkStudentOpen: false });
                    }}
                />
                <div
                style={{
                    paddingTop: "15px",
                    paddingRight: "25px",
                    paddingLeft: "25px",
                }}
                >
                    <div style={{ textAlign: "right", paddingBottom: "10px" }}>
                        <Button
                            style={{ margin: "10px" }}
                            onClick={() => {
                            this.setState({ newBulkStudentOpen: true });
                            }}
                        >
                            Bulk update Students from csv
                        </Button>
                    </div>
                </div>
                    <Table 
                        columns={this.studentColumns}
                        data={this.state.studentData}
                        editFunc={ (oldValue, newValue, row, column) => {
                            {   if(oldValue !== newValue){
                                    if( ['id','first_name','last_name','address'].includes(column.dataField) ){
                                        //should not be able to edit those fields.
                                        alert("you can only edit phone numbers")
                                        return this.getStudents();
                                    }
                                    if(column.dataField != "primary_number"){ 
                                        if(newValue == ''){
                                            if(row.primary_number === column.dataField){
                                                alert('please change the primary number before deleting it');
                                                return this.getStudents();
                                            }
                                        }
                                        let infoType = this.phoneTypes.indexOf(column.dataField) != -1 ? 'phone' : 'more_infos';
                                        return this.updateInfo(row.id, column.dataField, newValue, infoType);
                                    }else{
                                        if( row[newValue] === '' ){
                                            alert('you need a number before you can make it primary');
                                            // this.setState({studentData:[]});
                                            return this.getStudents();
                                        }else{
                                            return this.updatePrimary(row.id, newValue);
                                        }
                                    }
                                }
                            }
                        }}
                        />
            </div>
        )
    }

}
export default PhoneNumbers;