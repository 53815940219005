import React from "react";
import Button from "react-bootstrap/Button";
import Table from "./Table";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";
import { Type } from "react-bootstrap-table2-editor";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Form } from "bootstrap-4-react";
import Select from "react-select";
import baseUrl, { authentication, getGrades, getTeachers } from "../Utils";

const options = [
  // { value: '1', label: '1' },
  { value: "-1", label: "-1" },
  { value: "-2", label: "-2" },
  { value: "-3", label: "-3" },
  { value: "-4", label: "-4" },
  { value: "-5", label: "-5" },
];
class NewReportCardComments extends React.Component {
  info = [];
  reset = () => {
    this.setState({
      studentData: [],
      defaultGradeSelectVal: "default",
      defaultClassSelectVal: "default",
      defaultTeacherSelectVal: "default",

      teacherId: "",
      studentPoints: new Map(),

      grade: "",

      // classes:[],
    });
  };
  constructor(props) {
    super(props);
    this.state = {
      classId: "",
      date: "",
      studentData: [],
      defaultGradeSelectVal: "default",
      defaultClassSelectVal: "default",

      studentPoints: new Map(),
      grades: [],
      grade: "",
      term: "",

      classes: [],
      defaultTermSelectVal: "default",
    };
    //this.getClasses()
    getGrades(this);
    getTeachers(this);
    //this.getStudents()
  }
  // getClasses(){
  //     var url2 = new URL("http://ohrhatorah.appsenses.com/getClasses.php")
  //     fetch(url2).then(response=>{ return response.json();}).then(res=>{
  //         res.forEach((a)=>{a.id=parseInt(a.id)})
  //         this.setState({classes:res})
  //
  //     })
  // }
  getClasses(grade) {
    fetch(`${baseUrl}/grade/${grade}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });
        this.setState({ classes: res });
      });
  }

  setClass() {
    if (this.state.classId != "" && this.state.term != "") {
      let formData = new FormData();
      formData.append("action", "get class");
      formData.append("id", this.state.classId);

      let url = `${baseUrl}/really-bare-class/${this.state.classId}`;
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          res.map((item) => {
            item.id = parseInt(item.id);
            item.absent = "";

            this.info.push(item);
          });
          this.getOldComments(res);
        });
    }
  }
  getOldComments(students) {
    if (this.state.term != "" && this.state.classId != "") {
      let url = `${baseUrl}/reportcard-comments/${this.state.classId}/${this.state.term}`;
      fetch(url, {
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          res.forEach((a) => {
            students.forEach((b) => {
              if (a.id == b.id) {
                b.comment = a.comment;
              }
            });

            a.id = parseInt(a.id);
          });
          this.setState({ studentData: students });
        });
    }
  }

  absentFormatter = (cell, row, rowIndex) => {
    let data = this.state.studentData;
    console.log(row);
    data.map((d) => {
      if (d.id == row.id) {
        d.absent = !d.absent;
      }
    });

    this.setState({ studentData: data });
  };
  /*{
        dataField: 'id',
        text: 'Id',
        sort: true
    },*/

  studentColumns = [
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
      editable: false,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
      editable: false,
    },
    {
      dataField: "comment",
      text: "Comment",
      editor: {
        type: Type.TEXTAREA,
      },
    },
  ];

  validate = () => {
    return true;
  };
  save = () => {
    if (this.validate()) {
      let obj = {};

      // let date = this.state.formattedDate;
      let students = {
        students: this.state.studentData,
        term: this.state.term,
        teacher: this.state.teacherId,
        class: this.state.classId,
      };
      students = JSON.stringify(students);

      let formData = new FormData();
      formData.append("data", students);

      console.log(students);

      ///${this.state.teacherId}/${date}/${this.state.classId}
      fetch(`${baseUrl}/new-reportcard-comments`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())

        .then((text) => {
          students = "";

          if (text == "successful") {
            this.reset();
          } else {
            alert(text);
          }
        });
    }
  };

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>

          <Breadcrumb.Item active>New Day</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <div style={{ paddingRight: "30px", paddingBottom: "10px" }}>
            <Button
              style={{ float: "right" }}
              onClick={() => {
                this.save();
              }}
            >
              Save
            </Button>
          </div>
          <div
            className="row"
            style={{
              textAlign: "center",
              padding: "15px",
              paddingLeft: "25px",
            }}
          >
            <div
              className="col-sm-12 col-lg-6"
              style={{
                padding: "15px",
                paddingLeft: "25px",
                textAlign: "left",
              }}
            >
              <Form.CustomSelect
                style={{ maxWidth: "300px" }}
                lg
                mb="3"
                value={this.state.defaultTermSelectVal}
                onChange={(v) => {
                  if (v.target.value != "default") {
                    this.setState(
                      {
                        term: v.target.value,
                        defaultTermSelectVal: v.target.value,
                      },
                      this.setClass
                    );
                  }
                }}
              >
                <option value="1">Term 1</option>
                <option value="2">Term 2</option>
                <option value="3">Term 3</option>

                {this.state.defaultTermSelectVal == "default" && (
                  <option value="default">Choose Term</option>
                )}
              </Form.CustomSelect>
            </div>

            <div className="col-sm-12 col-lg-6" style={{ paddingTop: "10px" }}>
              <Form.CustomSelect
                lg
                mb="3"
                value={this.state.defaultGradeSelectVal}
                onChange={(v) => {
                  if (v.target.value != "default") {
                    this.setState({
                      grade: v.target.value,
                      defaultGradeSelectVal: v.target.value,
                      defaultClassSelectVal: "default",
                    });
                    this.getClasses(v.target.value);
                    console.log(this.state.grade);
                  }
                }}
              >
                {this.state.grades.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.name}
                  </option>
                ))}
                {this.state.defaultGradeSelectVal == "default" && (
                  <option value="default">Choose Grade</option>
                )}
              </Form.CustomSelect>
            </div>
          </div>

          <div
            className="row"
            style={{
              textAlign: "center",
              padding: "15px",
              paddingLeft: "25px",
            }}
          >
            <div className="col-sm-6" style={{ paddingTop: "10px" }}>
              {this.state.grade != "" && (
                <Form.CustomSelect
                  lg
                  mb="3"
                  value={this.state.defaultClassSelectVal}
                  onChange={(v) => {
                    if (v.target.value != "default") {
                      this.setState(
                        {
                          classId: v.target.value,
                          defaultClassSelectVal: v.target.value,
                        },
                        this.setClass
                      );
                    }
                  }}
                >
                  {this.state.classes.map((x, y) => (
                    <option value={x.id} key={y}>
                      {x.class}
                    </option>
                  ))}
                  {this.state.defaultClassSelectVal == "default" && (
                    <option value="default">Choose Class</option>
                  )}
                </Form.CustomSelect>
              )}
            </div>
          </div>

          {this.state.studentData.length > 0 && (
            <div style={{ padding: "15px" }}>
              <Table
                pagination={false}
                selectable={false}
                delete={false}
                switch={false}
                editable={true}
                editFunc={(oldValue, newValue, row, column) => {
                  if (column.text == "Comment") {
                    this.info.map((item, index) => {
                      if (item.id == row.id) {
                        item.comment = newValue;
                      }
                    });
                  }
                }}
                columns={this.studentColumns}
                data={this.state.studentData}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default NewReportCardComments;
