import React, {useState, useEffect} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import baseUrl, { loginFunction } from "../Utils";

const Login = (props) => {
    const [state, setState] = useState({
      username: "",
      password: "",
      displayName: '',
      isAdmin: false,
      isNewUser: false,
      isDeleting: false,
      users: {},
      theIdOfUserBeingDeleting: 0,
    });

    useEffect(() => {
      getUsers();
    }, [])

  const login = () => {
    loginFunction(state.username, state.password, () => {
      window.location = "/home";
    });

    /*let formData=new FormData()
                formData.append("username",state.username)
                formData.append("password",state.password)
                var url = "http://ohrhatorah.appsenses.com/login.php"
                url=baseUrl+`/login`
              */
    /*                            fetch(url, {
                        method: 'POST',
                        body: formData

                    }
                ).then(response=>{console.log(response); return response.json()}).then(res=>{
console.log(getCookie("username"))
                    console.log(cookie.loadAll())
                    console.log(res)
                    window.authentication=res.authentication
                   // alert(window.authentication)
                    if(res.login=="loggedin"){
                       // setPassword(res.authentication);
                       // window.location='/home'
                        // WILL ONLY WORK IN CLASS - REFACTOR FOR FUNCTION!
                       // /!* this.history.push({
                       //      pathname: '/home'
                       //  })*!/
                       // document.cookie = "username="+this.state.username;

                        //this.props.callback()
                    }

                })*/
  }

  const getUsers = () => {
    fetch(`${baseUrl}/get-users`)
      .then((response) => {
        return response.json();
      })
      .then(res => {
        setState({ ...state, users: res.reduce((pre, cur) => ({ ...pre, [cur.id]: cur }), {}) })
      })
      .catch(e => { console.error(e); alert(e) })
  }

  const deleteUser = (id) => {
    if (!id || !window.confirm(`Are you sure you want to delete ${state.users[id].displayName}`)) {
      setState({ ...state, theIdOfUserBeingDeleting: 0 })
      return;
    }

    let formData = new FormData();
    formData.append("id", id);
    fetch(`${baseUrl}/delete-user`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then(() => {
        setState({ ...state, isDeleting: false })
        getUsers()
      })
      .catch(e => { console.error(e); alert(e) })
  }

  const createNewUser = () => {
    const { username, password, displayName, isAdmin } = state;
    if (!username || !password || !displayName) return alert('You must fill all fields!')

    let formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("displayName", displayName);
    formData.append("isAdmin", isAdmin);

    fetch(`${baseUrl}/create-new-user`, {
      method: "POST",
      body: formData,
    })
      .then(response => {
        return response.text();
      })
      .then(data => {
        if (data.includes('There is already an active user with that User Name. Please try again')) return alert(data)

        setState({ ...state, isNewUser: false })
        getUsers()
      })
      .catch(e => { console.error(e); alert(e) })
  }

    const { isNewUser, isDeleting, users, theIdOfUserBeingDeleting } = state
    return (
      <div>
        <Modal show={true}>
          <Modal.Header closeButton>
            <Modal.Title>{isNewUser ? 'Create New User' : 'Login'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ textAlign: "center", padding: "15px" }}>
              <input
                onChange={(e) => {
                  setState({ ...state, username: e.target.value });
                }}
                type="text"
                placeholder="Username"
                style={{ width: "75%", padding: "5px" }}
              />
            </div>
            <div style={{ textAlign: "center", padding: "15px" }}>
              <input
                type="password"
                onChange={(e) => {
                  setState({ ...state, password: e.target.value });
                }}
                placeholder="Password"
                style={{ width: "75%", padding: "5px" }}
              />
            </div>
            {isNewUser && (<>
              <div style={{ textAlign: "center", padding: "15px" }}>
                <input
                  type="text"
                  onChange={(e) => {
                    setState({ ...state, displayName: e.target.value });
                  }}
                  placeholder="Display Name"
                  style={{ width: "75%", padding: "5px" }}
                />
              </div>
              <div style={{ textAlign: "center", padding: "15px" }}>
                <label>Is User Administrator</label>
                <input
                  type="checkbox"
                  id="isAdmin"
                  name="isAdmin"
                  value={state.isAdmin}
                  onChange={(e) => setState({ ...state, isAdmin: !state.isAdmin })}
                />
              </div>
            </>)}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => isNewUser ? createNewUser() : login()}
              variant="primary"
            >
              {isNewUser ? 'Save' : 'Login'}
            </Button>
            {(isNewUser || isDeleting) && <Button variant="secondary" onClick={() => setState({ ...state, isNewUser: false, isDeleting: false })}>Back</Button>}
            {!isNewUser && <Button variant="secondary" onClick={() => setState({ ...state, isNewUser: true, isDeleting: false })}>Create New User</Button>}
            {!isDeleting && <Button variant="secondary" onClick={() => setState({ ...state, isDeleting: true })}>Delete a User</Button>}
            {isDeleting && <select value={theIdOfUserBeingDeleting} onChange={e => deleteUser(e.target.value)}><option key={0} value={0}>Select a User</option>
              {Object.keys(users).map(id => (<option key={id} value={id}>{users[id].displayName}</option>))}
            </select>}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

Login.propTypes = {};
function getCookie(name) {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = document.cookie.split(";");

  // Loop through the array elements
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");

    /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
    if (name == cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
}

export default Login;
