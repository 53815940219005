import React from "react";

import Table from "./Table";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";

import baseUrl, { authentication } from "../Utils";
import { updateChannel } from "../Utils";
import { Form } from "bootstrap-4-react";
import Button from "react-bootstrap/Button";

const columns = [
  {
    dataField: "id",
    text: "Id",
    sort: true,
  },
  { dataField: "last_name", text: "Last Name", sort: true },
  {
    dataField: "first_name",
    text: "First Name",
    sort: true,
  },
  {
    dataField: "average",
    text: "Average",
    sort: true,
  },
  {
    dataField: "grade",
    text: "Grade",
    sort: true,
  },
  {
    dataField: "class",
    text: "Class",
    sort: true,
  },
];

class HonorRole extends React.Component {
  constructor(props) {
    super(props);

    this.state = { term: "", defaultTermSelectVal: "default" };
  }
  setHonorRole(term, submit) {
    fetch(`${baseUrl}/honor-role/${term}/${submit}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.honorRole.forEach((a) => {
          a.id = parseInt(a.id);
        });
        res.withHonors.forEach((a) => {
          a.id = parseInt(a.id);
        });
        this.setState({
          honorRole: res.honorRole,
          withHonors: res.withHonors,
          losers: res.losers,
        });
      });
  }

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Honor Role</Breadcrumb.Item>
          {/* <Breadcrumb.Item href="grades">Grades</Breadcrumb.Item>

*/}
        </Breadcrumb>

        <div style={{ padding: "25px" }}>
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <div style={{ paddingRight: "30px", paddingBottom: "10px" }}>
              <Button
                style={{ float: "right" }}
                onClick={() => {
                  if (
                    prompt(
                      "This action is restricted. Please enter password"
                    ) == "1298"
                  ) {
                    this.setHonorRole(this.state.term, true);
                  }
                }}
              >
                Give terms Honor role points
              </Button>
            </div>
            <div style={{ padding: "15px", paddingLeft: "25px" }}>
              <Form.CustomSelect
                style={{ maxWidth: "300px" }}
                lg
                mb="3"
                value={this.state.defaultTermSelectVal}
                onChange={(v) => {
                  if (v.target.value != "default") {
                    this.setState(
                      {
                        term: v.target.value,
                        defaultTermSelectVal: v.target.value,
                      },
                      this.setHonorRole(v.target.value, false)
                    );
                  }
                }}
              >
                <option value="1">Term 1</option>
                <option value="2">Term 2</option>
                <option value="3">Term 3</option>
                {this.state.defaultTermSelectVal == "default" && (
                  <option value="default">Choose Term</option>
                )}
              </Form.CustomSelect>
            </div>
          </div>
          {this.state.honorRole && this.state.honorRole.length > 0 && (
            <div style={{ paddingBottom: 20 }}>
              <h3>Honor Role</h3>
              <Table
                delete={false}
                switch={false}
                columns={columns}
                data={this.state.honorRole}
                rowEvents={{
                  onClick: (e, row, rowIndex) => {
                    console.log(e);
                    if (e.type == "click") {
                      //this.props.stateFunc(row.image)
                      this.props.history.push({
                        pathname: `/student-details/${row.student_id}`,
                        state: {
                          class: row.class,
                          classId: row.class_id,
                          gradeId: row.grade_id,
                          grade: row.grade,
                          studentId: row.student_id,
                          firstName: row.first_name,
                          lastName: row.last_name,
                        },
                      });
                    }
                  },
                }}
              />
            </div>
          )}
          {this.state.withHonors && this.state.withHonors.length > 0 && (
            <div>
              <h3>Honor Role With Honors</h3>
              <Table
                delete={false}
                switch={false}
                columns={columns}
                data={this.state.withHonors}
                rowEvents={{
                  onClick: (e, row, rowIndex) => {
                    console.log(e);
                    if (e.type == "click") {
                      //this.props.stateFunc(row.image)
                      this.props.history.push({
                        pathname: `/student-details${row.student_id}`,
                        state: {
                          class: row.class,
                          classId: row.class_id,
                          gradeId: row.grade_id,
                          grade: row.grade,
                          studentId: row.student_id,
                          firstName: row.first_name,
                          lastName: row.last_name,
                        },
                      });
                    }
                  },
                }}
              />
            </div>
          )}
          {this.state.losers && this.state.losers.length > 0 && (
            <div>
              <h3>Losers</h3>
              <Table
                delete={false}
                switch={false}
                columns={columns}
                data={this.state.losers}
                rowEvents={{
                  onClick: (e, row, rowIndex) => {
                    console.log(e);
                    if (e.type == "click") {
                      //this.props.stateFunc(row.image)
                      this.props.history.push({
                        pathname: `/student-details${row.student_id}`,
                        state: {
                          class: row.class,
                          classId: row.class_id,
                          gradeId: row.grade_id,
                          grade: row.grade,
                          studentId: row.student_id,
                          firstName: row.first_name,
                          lastName: row.last_name,
                        },
                      });
                    }
                  },
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default HonorRole;
