import React from "react";
import Table from "./Table";
import { FaChevronDown, FaCheck, FaTimes } from "react-icons/fa";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";
import Button from "react-bootstrap/Button";
import baseUrl, { authentication } from "../Utils";

class Tests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studentData: [],
    };
    this.getTests();
  }
  studentColumns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,
    },
    {
      dataField: "subject",
      text: "Subject",
      sort: true,
    },
    {
      dataField: "topic",
      text: "topic",
      sort: true,
    },
    {
      dataField: "grade",
      text: "Grade",
      sort: true,
    },
    {
      dataField: "class",
      text: "Class",
      sort: true,
    },
    {
      dataField: "room",
      text: "Room",
      sort: true,
    },
    {
      dataField: "term",
      text: "Term",
      sort: true,
    },
    {
      dataField: "average",
      text: "Average",
      sort: true,
    },
  ];

  getTests() {
    var url = "http://ohrhatorah.appsenses.com/test.php";
    fetch(`${baseUrl}/tests`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });

        res.map((item) => {
          item.id = parseInt(item.id);
        });
        this.setState({ studentData: res });
      });
  }
  deleteTests = () => {
    let record = {};
    record.testIds = this.tableRef.handleGetSelectedData();
    let formData = new FormData();
    // formData.append("action","delete tests")
    formData.append("tests", JSON.stringify(record));
    //formData.append('record', JSON.stringify(record))
    var url = "http://ohrhatorah.appsenses.com/test.php";

    fetch(`${baseUrl}/delete-tests`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: authentication,
      },
    }).then((response) => {
      this.getTests();
    });

    /* return response.json();}).then(res=>{
            res.map((item)=>{
                item.id=parseInt(item.id)

            })
            this.setState({studentData:res})
        })*/
  };

  defaultSorted = [
    {
      dataField: "date", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Tests</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ padding: "25px" }}>
          <div style={{ paddingBottom: "25px", textAlign: "right" }}>
            <Button
              onClick={() => {
                this.props.history.push({
                  pathname: "/new-test",
                });
              }}
            >
              New Test
            </Button>
          </div>

          {
            <Table
              ref={(ref) => (this.tableRef = ref)}
              switch={false}
              defaultSorted={this.defaultSorted}
              columns={this.studentColumns}
              data={this.state.studentData}
              deleteClick={this.deleteTests}
              rowEvents={{
                onClick: (e, row, rowIndex) => {
                  if (e.type == "click") {
                    this.props.history.push({
                      pathname: "/test-details",
                      state: {
                        testId: row.id,
                        topic: row.topic,
                        date: row.date,
                      },
                    });
                  }
                },
              }}
            />
          }
        </div>
      </div>
    );
  }
}

export default Tests;
