import React, { useRef } from 'react';
import QRCode from "react-qr-code";
import Button from "react-bootstrap/Button";
import ReactToPrint from "react-to-print";

const urlSearchParams = new URLSearchParams(window.location.search);
const { firstName, lastName, studentId } = Object.fromEntries(urlSearchParams.entries());

export const IDCard = () => {
    let IDCardRef = useRef();
    return (
        <div className='idcard'
            style={{
                width: '90%',
                margin: 'auto',
            }}
        >
            <ReactToPrint
                trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return <Button>Print Student ID Card</Button>
                }}
                content={() => IDCardRef}
            />
            <div
                ref={(el) => (IDCardRef = el)}
                style={{ margin: 'auto', width: '60%' }}
            >
                <h1>{firstName} {lastName}</h1>
                <QRCode value={`${window.location.href}/${studentId}`}></QRCode>
            </div>
        </div>
    )
}